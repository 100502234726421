import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, Image, Table, Button, Modal, Form } from 'react-bootstrap';
import { Popover, Upload } from 'antd';
import '../Intranet.css';



const ImageDeleteButtons = ({image}) => {

        const [imageShow, setImageShow] = useState(false);


        return (
          <Container fluid className="px-0">
            {/* The Image button should only appear for notes that have an image attached. */}
            <Popover
              content={<div className="text-center bps-midnight">Image</div>}
              trigger="hover"
            >
              <a
                className="bps-icon-btn m-1"
                onClick={() => setImageShow(true)}
              >
                <Image src="icons/image.svg" width="20px" />
              </a>
            </Popover>

            <Modal
              onHide={() => setImageShow(false)}
              show={imageShow}
              size="lg"
              centered
            >
              <img src={image} width={700} height={500} className="mx-auto" />
            </Modal>

            {/* The Delete button should appear on every note individually */}
            {/* <Popover
                    content=
                    {<div className='text-center bps-midnight'>
                        Delete
                    </div>}
                    trigger="hover">
                    <a className='bps-icon-btn m-1'>
                        <Image src='icons/delete.svg' width='20px' />
                    </a>
                </Popover> */}
          </Container>
        );
    }

export default ImageDeleteButtons;