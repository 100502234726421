import { gql, useMutation } from "@apollo/client";
import { Button, Input, message, Modal, Radio, Spin, Form } from "antd";
import Dragger from "antd/es/upload/Dragger";
import React, { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Constants, { patterns, roles } from "../../Constants";
import { Row, Col } from "react-bootstrap";

const GET_ALL_CLIENTS = gql`
query GetAllClientWithCompleteDetails($filter: [String]!) {
getAllClientWithCompleteDetails(filter: $filter) {
  client {
    _id
    name
    image
    type
    isActive
    createdBy
  }
  program {
    _id
    name
    header {
      label
      value
    }
    isActive
    createdBy
    client
    programView {
      _id
      name
      stagingSite
      liveSite
      isActive
      createdBy
      program
      notes {
        _id
        note
        image
        isActive
        createdBy
        userId
        createdAt
        programView
      }
    }
  }
}
}
`;


const ADD_CLIENT = gql`
mutation AddClient($input: ClientInput, $file: Upload) {
  addClient(input: $input, file: $file) {
    message
    code
  }
}
`;


export default function AddClientForm() {
  const [form] = Form.useForm();
  const [fileList, setFilesForList] = useState([]);
  const [fileSizeError, setErrorForFileSize] = useState(false);
  const [fileTypeError, setErrorForFileType] = useState(false);
  const [files, setfileList] = useState();
  const createdBy = secureLocalStorage.getItem(Constants.username);
  const [spinloadingForm, setSpinloadingForm] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const showClientAdd = [roles.ROOT];
  const currentRole = secureLocalStorage.getItem(Constants.userRole);
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const [addClient] = useMutation(ADD_CLIENT, {
    onCompleted(data) {
      setVisible(false);
      setSpinloadingForm(false);
      if (data.addClient.code === "201") {
        messageApi.open({
          type: "success",
          content: "Client added successfully !",
          duration: 5,
          style: {
            marginTop: "10vh",
          },
        });
      }
      setfileList();
      setFilesForList();
      setErrorForFileType();
      setErrorForFileSize();
      form.resetFields();
    },
    onError(error) {
      setVisible(false);
      setSpinloadingForm(false);
      messageApi.open({
        type: "error",
        content: "Failed to add client",
        duration: 5,
        style: {
          marginTop: "10vh",
        },
      });
      setfileList();
      setFilesForList();
      setErrorForFileType();
      setErrorForFileSize();
      form.resetFields();
    },
    refetchQueries:[GET_ALL_CLIENTS]
  });

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
    setfileList();
    setFilesForList();
    setErrorForFileType();
    setErrorForFileSize();
  };

  const handleImage = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const propsUpload = {
    name: "file",
    maxCount: 1,
    accept: "image/jpeg,image/jpg,image/png",
    customRequest: handleImage,
    onChange({ file, fileList, event }) {
      file.size > 10485760
        ? setErrorForFileSize(true)
        : setErrorForFileSize(false);
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg" &&
      file.type !== "image/png"
        ? setErrorForFileType(true)
        : setErrorForFileType(false);
      setfileList(file.originFileObj);
      let newFileList = [file];
      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url = file.originFileObj.name;
        }
        return file;
      });
      setFilesForList(newFileList);
    },
    onRemove({ file }) {
      setErrorForFileSize(false);
      setErrorForFileType(false);
      setfileList();
      setFilesForList([]);
      let newFileList = [file];
      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url = file.originFileObj.name;
        }
        return file;
      });
      setFilesForList(newFileList);
    },
  };

  const getClientDetails = (values) => {
    setSpinloadingForm(true);
    addClient({
      variables: {
        input: {
          name: values.clientName,
          type: values.clientType,
          isActive: true,
          createdBy,
        },
        file: files,
      },
    });
    form.resetFields();
  };

  return (
    <>
      {contextHolder}
      <Button
        hidden={!showClientAdd.includes(currentRole)}
        className="btn-sm my-1 w-100 bps-secondary-button-sm"
        onClick={showModal}
      >
        + Client
      </Button>
      <Modal
        title="Add Client"
        open={visible}
        width={500}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              getClientDetails(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        onCancel={() => {
          handleCancel();
        }}
        cancelButtonProps={{
          display: "none",
        }}
      >
        <Spin spinning={spinloadingForm}>
          <Form
            form={form}
            name="clientForm"
            layout="vertical"
            onFinish={getClientDetails}
          >
            <Row>
              <Col>
                <Form.Item
                  name="clientName"
                  label="Client Name"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Client's Name",
                    },
                    {
                      pattern: patterns.namePattern,
                      message:
                        "Client name can only start with an alphabetic character",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="clientType"
                  label="Client Type"
                  rules={[
                    {
                      required: true,
                      message: "Client Type is Required",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="EXTERNAL">External</Radio>
                    <Radio value="INTERNAL">Internal</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item
                name="clientLogo"
                label="Client Logo"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (fileSizeError) {
                        return Promise.reject(
                          "File size should be less than 10mb"
                        );
                      } else if (fileTypeError) {
                        return Promise.reject(
                          "Only file types png,jpeg,jpg are allowed"
                        );
                      } else {
                        return Promise.resolve("File Uploaded Successfully");
                      }
                    },
                  }),
                  {
                    required: true,
                    message: "Please Enter Client's Logo",
                  },
                ]}
              >
                <Dragger {...propsUpload} fileList={fileList}>
                  <p className="ant-upload-text">
                    Click or drag your logo to this area to upload
                  </p>
                </Dragger>
              </Form.Item>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}
