import React, { useEffect, useState } from "react";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import "./Intranet.css";
import IntranetNavigation from "./IntranetNavigation";
import { gql, useLazyQuery } from "@apollo/client";
import ClientCard from "./ClientProgramTables/ClientCard";
import secureLocalStorage from "react-secure-storage";
import Constants, { roles } from "../Constants";
import DeleteClient from "./ClientProgramTables/DeleteClient";
import AddClientForm from "./ClientProgramTables/AddClientForm";
import EditClientForm from "./ClientProgramTables/EditClientForm";


const GET_ALL_CLIENTS = gql`
  query GetAllClientWithCompleteDetails($filter: [String]!) {
    getAllClientWithCompleteDetails(filter: $filter) {
      client {
        _id
        name
        image
        type
        isActive
        createdBy
      }
      program {
        _id
        name
        header {
          label
          value
        }
        isActive
        createdBy
        client
        programView {
          _id
          name
          stagingSite
          liveSite
          isActive
          createdBy
          program
          notes {
            _id
            note
            image
            isActive
            createdBy
            userId
            createdAt
            programView
          }
        }
      }
    }
  }
`;

const IntranetClientPrograms = () => {
  const [externalTypeArr, setExternalTypeArr] = useState([]);
  const [internalTypeArr, setInternalTypeArr] = useState([]);
  const currentRole = secureLocalStorage.getItem(Constants.userRole);
  const showClientAdd = [roles.ROOT];

  const [getClients] = useLazyQuery(GET_ALL_CLIENTS, {
    onCompleted(data) {
      const clients = data.getAllClientWithCompleteDetails;
      const internalFiltered = clients.filter(
        (data) => data.client.type === "INTERNAL"
      );
      const externalFiltered = clients.filter(
        (data) => data.client.type === "EXTERNAL"
      );
      // setAllClients(clients);
      internalFiltered.sort((a, b) =>
        a.client.name.toLowerCase().localeCompare(b.client.name.toLowerCase())
      );
      externalFiltered.sort((a, b) =>
        a.client.name.toLowerCase().localeCompare(b.client.name.toLowerCase())
      );
      setInternalTypeArr(internalFiltered);
      setExternalTypeArr(externalFiltered);
    },
    onError(err) {
      console.log(err);
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setExternalTypeArr([]);
    setInternalTypeArr([]);
    getClients({
      variables: {
        filter: ["true"],
      },
    });
  }, [getClients]);

  return (
    <Container fluid className="px-0 pb-4">
      <IntranetNavigation />
      <section className="pt-5 bps-bg-light">
        <Container className="pt-5">
          <Row className="p-5">
            <Col lg={8}>
              <h3 className="fs-36 bps-midnight">Client Programs</h3>
            </Col>
          </Row>
        </Container>
      </section>
      <Container fluid>
        <Row className="pt-lg-5">
          <Col>
            <Tab.Container>
              <Row>
                <Col sm={2} className="shadow-sm mb-4">
                  {internalTypeArr.length > 0 && <hr />}
                  {/* map the clients in this nav */}
                  <Nav variant="pills" className="flex-column">
                    {internalTypeArr.length > 0 &&
                      internalTypeArr.map((details) => {
                        return (
                          <Nav.Item 
                          key={details.client._id}
                          className="d-flex justify-content-between align-items-center"
                          >
                            <Nav.Link
                              className="d-flex justify-content-between align-items-center w-100"
                              eventKey={details.client._id}
                            >
                              {details.client.name}
                            </Nav.Link>
                              <div className="d-flex  align-items-center">
                                <EditClientForm
                                  clientId={details.client._id}
                                  getClients={getClients}
                                />
                                <DeleteClient
                                  clientId={details.client._id}
                                  getClients={getClients}
                                />
                              </div>
                          </Nav.Item>
                        );
                      })}

                    {externalTypeArr.length > 0 && <hr />}
                    {externalTypeArr.length > 0 &&
                      externalTypeArr.map((details) => {
                        return (
                          <Nav.Item 
                          className="d-flex justify-content-between align-items-center"
                          key={details.client._id}  >
                            <Nav.Link
                              eventKey={details.client._id}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              {details.client.name}
                            </Nav.Link>
                              <div className="d-flex  align-items-center">
                                <EditClientForm
                                  clientId={details.client._id}
                                  getClients={getClients}
                                />
                                <DeleteClient
                                  clientId={details.client._id}
                                  getClients={getClients}
                                />
                              </div>
                          </Nav.Item>
                        );
                      })}
                  </Nav>
                  <hr />
                  <Col sm={12}>
                    <AddClientForm />
                  </Col>
                </Col>
                <Col sm={10}>
                  <Tab.Content>
                    {internalTypeArr.length > 0 &&
                      internalTypeArr.map((client) => {
                        return (
                          <Tab.Pane eventKey={client.client._id}>
                            <ClientCard
                              client={client}
                              getClients={getClients}
                            />
                          </Tab.Pane>
                        );
                      })}
                    {externalTypeArr.length > 0 &&
                      externalTypeArr.map((client) => {
                        return (
                          <Tab.Pane eventKey={client.client._id}>
                            <ClientCard
                              client={client}
                              getClients={getClients}
                            />
                          </Tab.Pane>
                        );
                      })}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default IntranetClientPrograms;
