import { Table } from 'antd';
import React, { useEffect, useState } from 'react';

const DataTable = (props) => {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [pagination, setPagination] = useState({});
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            props.rowdata(selectedRows)
            setSelectedRowKeys(selectedRowKeys)
        },
    }
    const scrollValues = {
        x: props.scrollX,
        y: props.scrollY,
    }

    useEffect(() => {
        setSelectedRowKeys([])
        setPagination({
            pageSize: props.totalPages?.limit,
            total: props.totalPages?.totalDocs,
            current: props.totalPages?.currentPage,
            onChange: (ev) => {
                props.currentPage(ev);
            },
            showSizeChanger: false
        })
    }, [props.tableData, props.totalPages])
    return (
        <Table rowSelection={props.showCheck == true ? {
            ...rowSelection
        } : ""} scroll={scrollValues} columns={props.columnData} pagination={props.totalPages !== undefined ? pagination : true} dataSource={props.tableData} />
    )
}

export default DataTable;