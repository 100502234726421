import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../Intranet.css';

const Note = ({ note }) => {
  return (
    <Container fluid className="px-0">
      <Row>
        {/* <Col xs={1}>
          <img src="../icons/info.svg" width="20px" />
        </Col> */}
        <Col xs={11}>
          <p className="fs-16 d-inline">{note}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Note;