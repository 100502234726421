const Constants = {
    ifActive: 'isActive',
    email: 'email',
    picture: 'picture',
    username: 'name',
    loggedIn: 'loggedIn',
    userId: 'userId',
    token: 'token',
    userRole: 'userRole'
}
export const patterns = {
    passwordCheck: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
    phoneNumberCheckPattern: /^(?:\d*)$/,
    emailValidationPattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    websiteLinkValidationPattern: /^(?:https?|ftp):\/\/(?:(?:[A-Z0-9][A-Z0-9_-]*)(?:\.[A-Z0-9][A-Z0-9_-]*)*)(?::\d{1,5})?(?:\/[^\s]*)?$/i,
    namePattern: /^[a-zA-Z][a-zA-Z0-9\s]*$/,
    specialCharacterPattern:/[`!#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/,
}
export const roles = {
    STAFF: "STAFF",
    ADMINISTRATOR: "ADMINISTRATOR",
    ROOT: "ROOT_ADMIN",
}

export const rolesName = {
    STAFF: "staff"
}

const SuccessMessage = {
    CREATED: "Data created successfully.",
    UPDATED: "Data updated successfully.",
    DELETED: "Data deleted successfully.",
    GET_ALL: "All Data returned successfully.",
    GET_BY_ID: "Data by Id returned successfully.",
    MAIL_SENT: "Mail sent successfully.",
    CHANGE_PASSWORD: "Password changed successfully.",
}

// const HttpStatusCode= {
//     OK: 200,
//     BAD_REQUEST: 400,
//     NOT_FOUND: 404,
//     INTERNAL_SERVER: 500,
//     ALREADY_EXISTS: 208,
//     FORBIDDEN: 403,
//     UNAUTHORIZED: 401,
// }

export const HttpStatusCodeForLogout = [
    400,
    404,
    500,
    403,
    "INTERNAL_SERVER_ERROR"
]

export const rolesArr = [
    { name: "Staff", value: "STAFF" },
    { name: "Administrator", value: "ADMINISTRATOR" }
];

export const userStatus = [
    { name: "Active", value: true },
    { name: "Inactive", value: false }
];

export default Constants;
