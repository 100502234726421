import { gql, useMutation } from "@apollo/client";
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import { Popover, Table, Modal, Form, Input, message, Spin, Space } from "antd";
import React, { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Constants, { patterns, roles } from "../../Constants";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";


const ADD_PROGRAM = gql`
  mutation BuildProgram($input: ProgramBuilder) {
    buildProgram(input: $input) {
      message
      code
    }
  }
`;

const AddProgramForm = ({ getClients , client }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [spinloadingForm, setSpinloadingForm] = useState(false);
  const createdBy = secureLocalStorage.getItem(Constants.username);
  const currentRole = secureLocalStorage.getItem(Constants.userRole);
  const showClientAdd = [roles.ROOT];

  const urlValidator = (rule, value, callback) => {
    if (value) {
      const urlPattern = patterns.websiteLinkValidationPattern;
      if (!urlPattern.test(value)) {
        callback("Please provide a valid site link");
      } else {
        const url = new URL(value);
        if (!url.hostname || url.hostname === "localhost") {
          callback("Please enter a valid domain");
        } else {
          callback();
        }
      }
    } 
     else {
      return Promise.resolve();
    }
  };

  const [addProgram] = useMutation(ADD_PROGRAM, {
    onCompleted(data) {
      getClients({
        variables: {
          filter: ["true"],
        },
      });
      if (data.buildProgram.code === "201") {
        messageApi.open({
          type: "success",
          content: "Program added successfully !",
          duration: 5,
          style: {
            marginTop: "10vh",
          },
        });
      }
    },
    onError(error) {
      getClients({
        variables: {
          filter: ["true"],
        },
      });
      messageApi.open({
        type: "error",
        content: "Failed to add program",
        duration: 5,
        style: {
          marginTop: "10vh",
        },
      });
      console.log(error);
    },
  });

  const addNewProgram = (values) => {
    let header = [];
    let programView = [];
    if (
      values.customerServicePhone !== null &&
      values.customerServicePhone !== undefined
    ) {
      header.push({
        label: "Customer Service Phone",
        value: values.customerServicePhone,
      });
    }
    if (
      values.customerServiceEmail !== null &&
      values.customerServiceEmail !== undefined
    ) {
      header.push({
        label: "Customer Service Email",
        value: values.customerServiceEmail,
      });
    }
    if (
      values.directCustomerServicePhone !== null &&
      values.directCustomerServicePhone !== undefined
    ) {
      header.push({
        label: "Direct Customer Service Phone",
        value: values.directCustomerServicePhone,
      });
    }
    if (values.emailInbox !== null && values.emailInbox !== undefined) {
      header.push({
        label: "Email Inbox",
        value: values.emailInbox,
      });
    }
    if (
      values.directCustomerServiceEmail !== null &&
      values.directCustomerServiceEmail !== undefined
    ) {
      header.push({
        label: "Direct Customer Service Email",
        value: values.directCustomerServiceEmail,
      });
    }
    if (
      values.zendeskOutboundPhone !== null &&
      values.zendeskOutboundPhone !== undefined
    ) {
      header.push({
        label: "Zendesk Outbound Phone",
        value: values.zendeskOutboundPhone,
      });
    }
    
  values.Admin && values.Admin.forEach((view) => {
      let newAdminView = {
        name: "Admin",
        stagingSite: view.adminStagingSite,
        liveSite: view.adminLiveSite,
        isActive: true,
        createdBy,
      };
      programView.push(newAdminView);
    });

   values.Consumer && values.Consumer.forEach((view) => {
      let newAdminView = {
        name: "Consumer",
        stagingSite: view.consumerStagingSite,
        liveSite: view.consumerLiveSite,
        isActive: true,
        createdBy,
      };
      programView.push(newAdminView);
    });
    
    setSpinloadingForm(true);
    addProgram({
      variables: {
        input: {
          program: {
            name: values.programName,
            header,
            isActive: true,
            createdBy,
            client: client.client._id,
          },
          programView
        },
      },
    });
    setVisible(false);
    setSpinloadingForm(false);
    form.resetFields();
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  return (
    <>
      {contextHolder}
      <Button
        hidden={!showClientAdd.includes(currentRole)}
        className="btn-sm my-1 bps-secondary-button-sm float-end align-items-center"
        onClick={() => {
          showModal();
        }}
      >
        +Program
      </Button>
      <Modal
        title="Add Program"
        open={visible}
        width={1000}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              addNewProgram(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        onCancel={() => {
          handleCancel();
        }}
      >
        <Spin spinning={spinloadingForm}>
          <Form
            form={form}
            name="programForm"
            layout="vertical"
            onFinish={addNewProgram}
          >
            <Row>
              <p>
                <b>Program Contact Information</b>
              </p>
            </Row>
            <Row>
              <Col>
                <Form.Item
                  label="Program Name"
                  name="programName"
                  validateFirst = {true}
                  rules={[
                    {
                      required: true,
                      message: "Please enter Program Name",
                    },
                    {
                      pattern: patterns.namePattern,
                      message:
                        "Program name can only start with an alphabetic character",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="customerServicePhone"
                  label="Customer Service Phone"
                  validateFirst = {true}
                  rules={[
                    {
                      min: 10,
                      message: "Phone number should be of 10 digit long.",
                    },
                    {
                      max: 10,
                      message: "Phone number should be of 10 digit long.",
                    },
                    {
                      pattern: patterns.phoneNumberCheckPattern,
                      message: "Phone numbers can only contain numbers.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="customerServiceEmail"
                  label="Customer Service Email"
                  validateFirst = {true}
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="directCustomerServicePhone"
                  label="Direct Customer Service Phone"
                  validateFirst = {true}
                  rules={[
                    {
                      min: 10,
                      message: "Phone number should be of 10 digit long.",
                    },
                    {
                      max: 10,
                      message: "Phone number should be of 10 digit long.",
                    },
                    {
                      pattern: patterns.phoneNumberCheckPattern,
                      message: "Phone numbers can only contain numbers.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="zendeskOutboundPhone"
                  label="Zendesk Outbound Phone"
                  validateFirst = {true}
                  rules={[
                    {
                      min: 10,
                      message: "Phone number should be of 10 digit long.",
                    },
                    {
                      max: 10,
                      message: "Phone number should be of 10 digit long.",
                    },
                    {
                      pattern: patterns.phoneNumberCheckPattern,
                      message: "Phone numbers can only contain numbers.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="emailInbox"
                  validateFirst = {true}
                  label="Email Inbox"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="directCustomerServiceEmail"
                  label="Direct Customer Service Email"
                  validateFirst = {true}
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <hr />
              <Row>
                <p>
                  <b>Program Website Links</b>
                </p>
              </Row>
              <Row className="my-2 py-2 bps-bg-light">
                <Form.List name="Consumer">
                  {(fields, { add, remove }) => (
                    <>
                      <Row className=" py-2 bps-bg-light">
                        <Col sm={10}>
                          <h6>Consumer Site</h6>
                        </Col>
                        <Col sm={2}>
                          <Form.Item>
                            <Button
                              className="btn-sm my-1 bps-secondary-button-sm float-end align-items-center"
                              onClick={() => add()}
                            >
                              + Sites
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                      {fields.map(({ key, name, ...restField }) => (
                        <Row className="my-2 py-2 bps-bg-light">
                          <Col>
                            <h6>STAGING SITE -CONSUMER</h6>
                            <Form.Item
                              {...restField}
                              name={[name, "consumerStagingSite"]}
                              validateFirst={true}
                              rules={[
                                // {
                                //   required: true,
                                //   message: "Please enter Consumer Staging Site",
                                // },
                                { validator: urlValidator },
                              ]}
                            >
                              <Input placeholder={"https://staging-sunpower.bp.solutions"}/>
                            </Form.Item>
                          </Col>
                          <Col>
                            <h6>LIVE SITE -CONSUMER</h6>
                            <Form.Item
                              {...restField}
                              name={[name, "consumerLiveSite"]}
                              validateFirst={true}
                              rules={[
                                // {
                                //   required: true,
                                //   message: "Please enter Consumer Live Site",
                                // },
                                { validator: urlValidator },
                              ]}
                            >
                              <Input placeholder={"https://staging-sunpower.bp.solutions"}/>
                            </Form.Item>
                          </Col>
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => remove(name)}
                            />
                        </Row>
                      ))}
                    </>
                  )}
                </Form.List>
              </Row>
              <Row className="my-2 py-2 bps-bg-light">
                <Form.List name="Admin">
                  {(fields, { add, remove }) => (
                    <>
                      <Row className=" py-2 bps-bg-light">
                        <Col sm={10}>
                          <h6>Administrator Site</h6>
                        </Col>
                        <Col sm={2}>
                          <Form.Item>
                            <Button
                              className="btn-sm my-1 bps-secondary-button-sm float-end align-items-center"
                              onClick={() => add()}
                            >
                              + Sites
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                      {fields.map(({ key, name, ...restField }) => (
                        <Row className="my-2 py-2 bps-bg-light">
                          <Col>
                            <h6>STAGING SITE -ADMIN</h6>
                            <Form.Item
                              {...restField}
                              name={[name, "adminStagingSite"]}
                              validateFirst={true}
                              rules={[
                                // {
                                //   required: true,
                                //   message: "Please enter Admin Staging Site",
                                // },
                                { validator: urlValidator },
                              ]}
                            >
                              <Input placeholder={"https://staging-sunpower.bp.solutions"}/>
                            </Form.Item>
                          </Col>
                          <Col>
                            <h6>LIVE SITE -ADMIN</h6>
                            <Form.Item
                              {...restField}
                              name={[name, "adminLiveSite"]}
                              validateFirst={true}
                              rules={[
                                // {
                                //   required: true,
                                //   message: "Please enter Admin Live Site",
                                // },
                                { validator: urlValidator },
                              ]}
                            >
                              <Input placeholder={"https://staging-sunpower.bp.solutions"}/>
                            </Form.Item>
                          </Col>                        
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => remove(name)}
                            />                         
                        </Row>
                      ))}
                    </>
                  )}
                </Form.List>
              </Row>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default AddProgramForm;
