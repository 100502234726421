import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Image, Card, Nav, Tab, Table, Button, ListGroup, Overlay, Form } from 'react-bootstrap';
import { Popover, Upload } from 'antd';
import './Intranet.css';
import IntranetNavigation from './IntranetNavigation';
import HealthFlyer from "../assets/2023_Health_Challenge_Flyer.pdf";


const IntranetClientPrograms
  = () => {


    return (

      <Container fluid className="px-0">
        <IntranetNavigation />
        <section className="pt-5 bps-bg-light">
          <Container className="pt-5">
            <Row className="p-5">
              <Col lg={8}>
                <h3 className="fs-36 bps-midnight">Employee Resources</h3>
              </Col>
            </Row>
          </Container>
        </section>
        <Container fluid>
          <Row>
            <Col>
              <Tab.Container>
                <Row className="pt-5">
                  <Col sm={2} className='shadow-sm'>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="paybenefits">Pay & Benefits</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="forms">Forms</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="policiesprocedures">Policies & Procedures</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey='employeedirectory'>Employee Directory</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="incentives">Incentives</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>

                  <Col sm={10} className="pt-5 pt-md-0">
                    <Tab.Content>

                      <Tab.Pane eventKey="paybenefits">
                        <h5 className='fw-bold bps-midnight border-bottom pb-2'>Pay & Benefits</h5>
                        <Row className="pb-4">
                          <Col lg={3} sm={6} className="pt-2 h-100">
                            <a href="https://login.adp.com/welcome" target="_blank">
                              <Card className="p-2 card-link">
                                <Card.Body>
                                  <Image src="icons/resources-adp.png" width='40px' />
                                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>ADP</h6>
                                </Card.Body>
                              </Card>
                            </a>
                          </Col>
                          <Col lg={3} sm={6} className="pt-2 h-100">
                            <a href="https://healthy.kaiserpermanente.org/southern-california/consumer-sign-on#/signon" target="_blank">
                              <Card className="p-2 card-link">
                                <Card.Body>
                                  <Image src="icons/resources-kaiser.png" width='40px' />
                                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>Kaiser Health Benefit- California</h6>
                                </Card.Body>
                              </Card>
                            </a>
                          </Col>
                        </Row>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <a href='https://drive.google.com/file/d/1t68sHwfwvbwQth6Kp264BCkkn04lx-HT/view' target='_blank' className='bps-link-buttonn staging-link'>Kaiser Coverage - Health & Vision Coverage Details</a>
                          </ListGroup.Item>
                        </ListGroup>
                      </Tab.Pane>

                      <Tab.Pane eventKey="forms">
                        <h5 className='fw-bold bps-midnight border-bottom pb-2'>Forms</h5>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <a href='https://docs.google.com/forms/d/e/1FAIpQLSdiYJUTpWsRun3MYYFwFG8gw2nMje8p74xnlU4yNqbVBUFjMA/viewform' className='bps-link-buttonn staging-link' target='_blank'>Time Off Request Form</a>
                          </ListGroup.Item>
                        </ListGroup>
                      </Tab.Pane>

                      <Tab.Pane eventKey="policiesprocedures">
                        <h5 className='fw-bold bps-midnight border-bottom pb-2'>Policies & Procedures</h5>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <a href='https://docs.google.com/document/d/1lpBuRYcJVlj426IWXcgTtBIHfpQTgk9p4X2RUchKY0o/edit' className='bps-link-buttonn staging-link' target='_blank'>Employee Handbook</a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <a href='https://docs.google.com/document/d/1UdeyAEhfKeAD7wdQUZXg1Teo-16JhIy0/edit' className='bps-link-button' target='_blank'>Change Management Policy</a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <a href='https://docs.google.com/document/d/1CtmdiHuQ1xjZN-U0lV4B0CJ7aXtrkbdk/edit' className='bps-link-buttonn staging-link' target='_blank'>Information Security Policy</a>
                          </ListGroup.Item>
                        </ListGroup>
                      </Tab.Pane>

                      <Tab.Pane eventKey='employeedirectory'>
                        <h5 className='fw-bold bps-midnight border-bottom pb-2'>Employee Directory</h5>
                        <ListGroup variant="flush">
                          
                          <ListGroup.Item>
                            <a href='https://docs.google.com/document/d/1_m-_VdCJ2hpeKapN8BPyOilkuzqg_b9m/edit' className='bps-link-buttonn staging-link' target='_blank'>Employee Directory</a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <a href='https://docs.google.com/spreadsheets/d/1u5dyIG07-d0zlro30ieG66yUQGpikPWO/edit#gid=667459378' className='bps-link-buttonn staging-link' target='_blank'>Employee Birthdays</a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <a href='https://docs.google.com/spreadsheets/d/1P-VvZKNThdOAsZq-tmas-j0lU8rtVKeX/edit#gid=932511659' className='bps-link-buttonn staging-link' target='_blank'>Employee Anniversaries</a>
                          </ListGroup.Item>
                        </ListGroup>
                      </Tab.Pane>

                      <Tab.Pane eventKey="incentives">
                        <h5 className='fw-bold bps-midnight border-bottom pb-2'>Incentives</h5>
                        <Row className="pb-4">
                          <Col lg={3} sm={6} className="pt-2 h-100">
                            <a href="https://bpsmyaccount.com/home" target="_blank">
                              <Card className="p-2 card-link">
                                <Card.Body>
                                  <Image src="icons/resources-bpsmyaccount.png" width='40px' />
                                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>BPS My Account</h6>
                                </Card.Body>
                              </Card>
                            </a>
                          </Col>
                        </Row>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <a href={HealthFlyer} className='bps-link-buttonn staging-link' target='_blank'>Health & Fitness Incentive Challenge</a>
                          </ListGroup.Item>
                        </ListGroup>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }

export default IntranetClientPrograms;