import { gql, useLazyQuery } from "@apollo/client";
import { message, Popover, Tooltip } from "antd";
import React, { useState } from "react";
import { Image, Button } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import Constants, { roles } from "../../Constants";

const DELETE_PROGRAMBYID = gql`
  query DeleteProgramById($programId: ID!) {
    deleteProgramById(programId: $programId) {
      message
      code
    }
  }
`;

export default function DeleteProgram(props) {
  
  const id = props.programId;

  const getAllClients=props.getallclients;

  const [popovershow, setPopOverShowHide] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const [deleteProgramById] = useLazyQuery(DELETE_PROGRAMBYID);
  const currentRole = secureLocalStorage.getItem(Constants.userRole);
  const showProgramDelete = [roles.ROOT];

  function closePopOver() {
    setPopOverShowHide(false);
  }

  function handleOpenChange(newOpen) {
    setPopOverShowHide(newOpen);
  }

  async function deleteProgram() {
    try {
      const res = await deleteProgramById({
        variables: {
            programId: id,
        },
      });
      if (res.data.deleteProgramById.code === "200") {
        getAllClients({
            variables:{
                filter:['true']
            }
        });
        setPopOverShowHide(false);
        messageApi.open({
          type: "success",
          content: "Program removed successfully",
          duration: 10,
          style: {
            marginTop: "10vh",
          },
        });
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error removing program",
        duration: 10,
        style: {
          marginTop: "10vh",
        },
      });
    }
  }

  return (
    <React.Fragment>
      {contextHolder}
      <Popover
        content={
          <>
            <div className="text-center bps-midnight">
              <p>Remove this Program ?</p>
              <Button
                className="d-inline-flex p-2 bps-secondary-button-sm m-1"
                onClick={deleteProgram}
              >
                Remove
              </Button>
              <Button
                className="d-inline-flex p-2 bps-secondary-button-sm m-1"
                onClick={closePopOver}
              >
                Cancel
              </Button>
            </div>
          </>
        }
        trigger="click"
        open={popovershow}
        onOpenChange={handleOpenChange}
      >
        <Tooltip title="Delete program" placement="bottom">
          <a
            hidden={!showProgramDelete.includes(currentRole)}
            className="bps-icon-btn m-1"
          >
            <Image src="icons/delete.svg" width="20px" />
          </a>
        </Tooltip>
      </Popover>
    </React.Fragment>
  );
}
