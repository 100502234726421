import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useMutation, gql, useLazyQuery, useQuery } from '@apollo/client';
import IntranetNavigation from './IntranetNavigation';
import { Container, Row, Col, Image,Button } from 'react-bootstrap';
import { Form, Modal, message, Spin } from 'antd';
import EditDeleteButtons from './EditDeleteButtons';
import './Intranet.css';
import '../stylingfiles/BPS.css';
import { Input, Select } from 'antd';
import Constants, { rolesArr, roles, patterns } from '../Constants'
import DataTable from '../common/DataTable';
import secureLocalStorage from "react-secure-storage";
import dateToPST from '../common/helperFunctions';

const { Option } = Select;


const ADD_USER = gql`
mutation SignUp($input: SignUpInput) {
  signUp(input: $input) {
    message
  }
}
`

const SEARCH_USER = gql`query SearchUserByString($page: Int, $searchString: String) {
  searchUserByString(page: $page, searchString: $searchString) {
    result {
      username
      name
      email
      id
      userRole
      phone
      isActive
      createdAt
      registeredDate
      createdBy
    }
    totalPages
    totalDocs
    limit
    currentPage
  }
}`;

const RESEND_ENROLLMENT_MAIL=gql`mutation ResendEnrollmentMailTOUser($email: String!) {
  resendEnrollmentMailTOUser(email: $email) {
    message
    code
  }
}`;

const IntranetUsers = () => {

  const [addUserShow, setAddUserShow] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const [allusers, setAllUserData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [total, setTotalPages] = useState();

  const [spinloading, setSpinnerLoading] = useState(false);

  const createdBy = secureLocalStorage.getItem(Constants.username);

  const [searchByKeyword, setSearchByKeyword] = useState('');

  const currentRole=secureLocalStorage.getItem('userRole');

  const [resendEmail]=useMutation(RESEND_ENROLLMENT_MAIL,{
    onCompleted(data){
      if(data.resendEnrollmentMailTOUser.code === "201"){
        messageApi.open({
          type: 'success',
          content: "Enrollment mail sent successfully",
          duration: 2,
          style: {
            marginTop: "10vh",
          }
        });
      }
    },
    onError(){
      messageApi.open({
        type: 'error',
        content: "Failed to send enrollment mail",
        duration: 5,
        style: {
          marginTop: "10vh",
        }
      });
    }
  })

  async function getUsers() {
    setSpinnerLoading(true);
    try {
      const res = await getUsersBysearch({
        variables: {
          "searchString": searchByKeyword,
          "page": currentPage,
        }
      })
      if (res.data.searchUserByString) {
        setSpinnerLoading(false);
      }
    }
    catch (error) {
      setSpinnerLoading(false);
      console.log(error);
    }
  }

  const [getUsersBysearch] = useLazyQuery(SEARCH_USER, {
    onCompleted(data) {
      setAllUserData([]);
      const DataObj = data.searchUserByString;
      setTotalPages({
        currentPage: DataObj.currentPage,
        limit: DataObj.limit,
        totalDocs: DataObj.totalDocs
      })
      DataObj.result.map((userinfo) => {
        if (userinfo.userRole !== roles.ROOT) {
          let user = {
            key: userinfo.id,
            createdAt: userinfo.createdAt,
            createdBy: userinfo.createdBy,
            email: userinfo.email,
            id: userinfo.id,
            isActive: userinfo.isActive,
            name: userinfo.name,
            phone: userinfo.phone,
            registeredDate: userinfo.registeredDate,
            userRole: userinfo.userRole,
            username: userinfo.username,
          };
          setAllUserData(prevuser => [...prevuser, user]);
        }
      })
    },
    fetchPolicy: 'network-only'
  }
  );

  const resendEnrollmentEmail=(email)=>{
    resendEmail({
      variables:{
        "email":email
      }
    })
  }

  const currentColumns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'id',
      className: 'fw-bold text-secondary',
      fixed: 'left',
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'id',
      className: 'text-secondary',
    },
    {
      title: 'ROLE',
      dataIndex: 'userRole',
      key: 'id',
      className: 'text-secondary',
      render: (text) => {
        let RoleName;
        rolesArr.map((item) => {
          if (item.value === text) {
            RoleName = item.name;
          }
        })
        return RoleName || text;
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      className: 'text-secondary',
      render: (text) => {
        return (text === true) ? 'Active' : 'Inactive'
      }
    },
    {
      title: 'CREATED DATE',
      dataIndex: 'createdAt',
      key: 'createdAt',
      className: 'text-secondary',
      render: (text) => {
        let date = dateToPST(+text)
        return date  
      }
    },
    {
      title: 'ENROLLED DATE',
      dataIndex: 'registeredDate',
      key: 'registeredDate',
      className: 'text-secondary',
      render: (text) => {
       if (text !== null) {
         let date = dateToPST(+text);
         return date;
       }  
      }
    },
    {
      title:"RESEND EMAIL",
      className: 'text-secondary',
      render:(text,record)=>{
        if(currentRole === roles.ROOT && (record.registeredDate==null)){
          return <div className="text-primary">
            <span className="cursor-pointed" onClick={()=>resendEnrollmentEmail(record.email)}>
              Resend Email
              </span>
          </div>
        }
      }
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      className: 'text-secondary text-end',
      render: (text, record) => <EditDeleteButtons userData={record.id} getUserCall={getUsers} />,
    },
  ];

  useEffect(() => {
    getUsers()
  }, [currentPage])

  const [addUser] = useMutation(ADD_USER, {
    onCompleted(data) {
      getUsers()
    }
  });

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const { name, email, role } = values;
    setSpinnerLoading(true)
    try {
      const res = await addUser({
        variables: {
          input: {
            email, name, username: name, userRole: role, createdBy,
          }
        }
      })
      setAddUserShow(false);
      setSpinnerLoading(false)
      if (res.data.signUp) {
        form.resetFields();
        setSpinnerLoading(false);
        messageApi.open({
          type: 'success',
          content: "User added successfully",
          duration: 10,
          style: {
            marginTop: "10vh",
          }
        });
      }
    }
    catch (error) {
      setSpinnerLoading(false);
      messageApi.open({
        type: 'error',
        content: error.message,
        style: {
          marginTop: "10vh",
        }
      });
    }
  };

  return (<>
    {contextHolder}
    <Spin spinning={spinloading}>
      <Container fluid className="px-0">
        <IntranetNavigation />
        <section className="pt-5 bps-bg-light">
          <Container className="pt-5">
            <Row className="p-5">
              <Col lg={8}>
                <h3 className="fs-36 bps-midnight">BPS Intranet Users</h3>
              </Col>
            </Row>
          </Container>
        </section>
        <Container>
          <Row className="pt-5">
            <Col>
              <h5 className="fw-bold bps-midnight pb-2">Current Users</h5>
            </Col>
            <Col>
              <Button
                className="btn-sm bps-secondary-button-sm float-end"
                onClick={() => setAddUserShow(true)}
              >
                + Add User
              </Button>
              <Modal
                title="Add user"
                centered
                open={addUserShow}
                onCancel={() => {
                  form.resetFields();
                  setAddUserShow(false)
                }}
                onOk={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      onFinish(values);
                    })
                    .catch((info) => {
                      console.log("Validate Failed:", info);
                    });
                }}
              >
                <Form form={form} layout="vertical">
                  <Form.Item
                    label="Full name"
                    name="name"
                    className='form-label-style'
                    rules={[
                      { required: true, message: "Please input your full name" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    className='form-label-style'
                    rules={[
                      { required: true, message: "Please input your email" }, {
                        type: 'email', message: 'Please enter a valid email address'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Role"
                    name="role"
                    className='form-label-style'
                    rules={[{ required: true, message: "Please select role" }]}
                  >
                    <Select>
                      {rolesArr.map((role) => (
                        <Option key={role.name} value={role.value}>
                          {role.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form>
              </Modal>
            </Col>
          </Row>
          <Row>
            <Row>
              <Col className="mb-2">
                <Input.Search
                  allowClear
                  className="w-25"
                  onSearch={(e) => {
                    let type = "none";
                    let searchkeyword = e.trim();
                    let format = patterns.specialCharacterPattern
                    let invalid = format.test(searchkeyword);
                    if (invalid) {
                      messageApi.open({
                        type: 'error',
                        content: "Search should not contain any special characters.",
                        duration: 5,
                        style: {
                          marginTop: "10vh",
                        }
                      });
                    } else {
                      getUsersBysearch({
                        variables: {
                          "searchString": searchkeyword,
                          "page": 1,
                        }
                      })
                    }
                    setSearchByKeyword(searchkeyword);
                  }}
                />
              </Col>
            </Row>
            <Col>
              <DataTable
                showCheck={false}
                totalPages={total}
                currentPage={(cp) => { setCurrentPage(cp) }}
                columnData={currentColumns}
                tableData={allusers}
                scrollX={600}
                className="border p-2"
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </Spin>
  </>
  );
}

export default IntranetUsers