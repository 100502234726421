import React from 'react';
import './App.css';
import { createClient } from "graphql-ws";
import { split, HttpLink } from '@apollo/client';

import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context"
import { getMainDefinition } from '@apollo/client/utilities';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import ProtectedRoutes, { AuthorizedUsers } from '../src/common/ProtectedRoutes'
import { AuthProvider } from './context/AuthProvider';
// Intranet
import IntranetSignIn from './Intranet/IntranetSignIn';
import IntranetForgotPassword from './Intranet/IntranetForgotPassword';
import IntranetResetPassword from './Intranet/IntranetResetPassword';
import IntranetHome from './Intranet/IntranetHome';
import IntranetApps from './Intranet/IntranetApps';
import IntranetUsers from './Intranet/IntranetUsers';
import IntranetSolutionsFest from './Intranet/IntranetSolutionsFest';
import IntranetClientPrograms from './Intranet/IntranetClientPrograms';
import IntranetEmployeeResources from './Intranet/IntranetEmployeeResources';
import Constants, { HttpStatusCodeForLogout, roles } from './Constants';
import secureLocalStorage from "react-secure-storage";
import { createUploadLink } from "apollo-upload-client";


const httpLink = createUploadLink({
  uri: process.env.REACT_APP_NODE_URL,
  headers: { 'Apollo-Require-Preflight': 'true' }
});


const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_NODE_URL_WSS,
  }),
);

const authLink = setContext((_, { headers }) => {
  const token = secureLocalStorage.getItem(Constants.token);
  return {
    headers: {
      ...headers,
      token: token ? `${token}` : "",
    },
  }
})

//onError
const errorlink = onError(
  ({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.map((item) => {
        if (HttpStatusCodeForLogout.includes(item.code)) {
          secureLocalStorage.clear();
            window.location.href = "/intranet-signin"
            console.log(item);
          }
        else {
          console.log(item);
        }
      })
    }
  }
);

const httpLinkErrHandling = ApolloLink.from([errorlink, httpLink]);

//use different links for subscription then others.
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLinkErrHandling),
);

//create apollo client
export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache()
});


function App() {

  const users = [roles.ROOT];
  const home = [roles.ROOT, roles.ADMINISTRATOR, roles.STAFF];
  const appsAndSoftwares = [roles.ROOT, roles.ADMINISTRATOR, roles.STAFF];
  const clientProgram = [roles.ROOT, roles.ADMINISTRATOR, roles.STAFF];
  const employeeResource = [roles.ROOT, roles.ADMINISTRATOR, roles.STAFF];
  const solutionsFest = [roles.ROOT, roles.ADMINISTRATOR, roles.STAFF];

  return (
    <AuthProvider>
      <div className="App">
        <Router>
          <Routes>
            {/* Intranet */}
            <Route exact path='/intranet-signin' element={<IntranetSignIn />} />
            <Route exact path='/intranet-forgotpassword' element={<IntranetForgotPassword />} />
            <Route path='/reset-password/:token' element={<IntranetResetPassword />} />
            <Route element={<ProtectedRoutes />}>

              <Route element={<AuthorizedUsers role={[...users]} />}>
                <Route exact path='/intranet-users' element={<IntranetUsers />} />
              </Route>

              <Route element={<AuthorizedUsers role={[...home]} />}>
                <Route exact path="/" element={<IntranetHome />} />
                <Route exact path='/intranet' element={<IntranetHome />} />
              </Route>

              <Route element={<AuthorizedUsers role={[...appsAndSoftwares]} />}>
                <Route exact path='/intranet-appssoftware' element={<IntranetApps />} />
              </Route>

              <Route element={<AuthorizedUsers role={[...clientProgram]} />}>
                <Route exact path='/intranet-clientprograms' element={<IntranetClientPrograms />} />
              </Route>

              <Route element={<AuthorizedUsers role={[...employeeResource]} />}>
                <Route exact path='/intranet-employeeresources' element={<IntranetEmployeeResources />} />
              </Route>

              <Route element={<AuthorizedUsers role={[...solutionsFest]} />}>
                <Route exact path='/intranet-Solutionsfest' element={<IntranetSolutionsFest />} />
              </Route>

            </Route>
          </Routes>
        </Router>
      </div>
    </AuthProvider>
  );
}

export default App;
