import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import { Popover, Upload, Table, Image } from 'antd';
import '../Intranet.css';


const SolutionsFest2022
    = () => {
        return (

            <Container fluid className="pb-4 px-0">
                <Row>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-01.jpg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-02.jpg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-24.jpeg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-04.jpg' />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-05.jpg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-06.jpg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-07.jpg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-08.jpg' />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-25.jpeg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-10.jpg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-11.jpeg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-12.jpeg' />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-13.jpeg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-14.jpg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-15.jpeg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-16.jpeg' />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-17.jpeg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-18.jpg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-19.jpeg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-20.jpeg' />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-21.jpeg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-22.jpeg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-23.jpeg' />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-09.jpg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2022/sf2022-03.jpg' />
                    </Col>
                </Row>
            </Container>
        );
    }

export default SolutionsFest2022;