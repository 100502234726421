import React, { Fragment, useEffect, useState } from "react";
import { Form, Input, message } from "antd";
import { useMutation, gql } from "@apollo/client";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./Intranet.css";
import "../stylingfiles/BPS.css";
import { useLocation } from "react-router-dom";

const sendForgotPasswordMail = gql`
  mutation SendForgotPasswordMail($email: String!) {
    sendForgotPasswordMail(email: $email) {
      message
    }
  }
`;

const IntranetForgotPassword = (props) => {
    const [form] = Form.useForm();
    const [sendForgotPassMail] = useMutation(sendForgotPasswordMail);
    const [messageApi, contextHolder] = message.useMessage();
    const location = useLocation();

    useEffect(() => {
        const emailFromRoute = location.state?.emailState;
        if (emailFromRoute !== null && emailFromRoute !== undefined && emailFromRoute !== "") {
            form.setFieldsValue({ email: emailFromRoute || "" })
        }
    }, [])

    const handleForgotPassword = async (values) => {
        const { email } = values;
        try {
            const res = await sendForgotPassMail({
                variables: {
                    email,
                },
            });

            if (res.data) {
                messageApi.open({
                    type: "success",
                    content: res.data.sendForgotPasswordMail.message,
                    duration: 10,
                });
            }
        } catch (error) {
            messageApi.open({
                type: "error",
                content: error.message,
                duration: 10,
            });
        }
    };

    return (
      <>
          {contextHolder}
          <Container fluid className="px-0">
              <section className="card-order-platform-bar shadow-sm">
                  <Container fluid>
                      <Row className="justify-content-between align-items-center">
                          <Col>
                              <a href="/">
                                  <img src="images/Logo.svg" className="logo" />
                              </a>
                          </Col>
                      </Row>
                  </Container>
              </section>

              <section className="bps-bg-light text-center">
                  <Container fluid className="py-5">
                      <Row className="justify-content-evenly">
                          <Col lg={5}>
                              <h1 className="fs-48 bps-midnight">Sign In</h1>
                          </Col>
                      </Row>
                  </Container>
              </section>

              <section>
                  <Container className="py-5">
                      <Row className="justify-content-center p-4">
                          <Col lg={4} className="bps-card py-5">
                              <Form
                                  layout="vertical"
                                  form={form}
                                  onFinish={handleForgotPassword}
                              >
                                  <Row className="justify-content-center">
                                      <Col lg={10}>
                                          <Form.Item
                                              className=" form-label-style"
                                              name="email"
                                                rules={[{ required: true, message: "Email is required" }, { type: "email", message: "please enter a valid email" }]}
                                              label="Email"
                                          >
                                              <Input />
                                          </Form.Item>
                                      </Col>
                                  </Row>
                                  <Row className="justify-content-center text-center pt-4">
                                      <Col lg={12}>
                                          <Button type="submit" className="bps-primary-button">
                                              Send Forgot Email
                                          </Button>
                                      </Col>
                                  </Row>
                              </Form>
                          </Col>
                        </Row>
                  </Container>
              </section>
          </Container>
      </>
  );
};

export default IntranetForgotPassword;
