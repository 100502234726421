import React, { Component, Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import IntranetNavigation from './IntranetNavigation';
import { Container, Row, Col, Image, Card } from 'react-bootstrap';
import './Intranet.css';
import '../stylingfiles/BPS.css';



const IntranetApps = () => {
  return (
    <Container fluid className="px-0">
      <IntranetNavigation />
      <section className="pt-5 bps-bg-light">
        <Container className="pt-5">
          <Row className="p-5">
            <Col lg={8}>
              <h3 className="fs-36 bps-midnight">Apps & Software</h3>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row className="p-lg-5">
          <Col lg={3} sm={6} className="pt-4 h-100">
            <a href="https://www.browserstack.com/users/sign_in" target="_blank">
              <Card className="p-2 card-link">
                <Card.Body>
                  <Image src="icons/browserstack.png" width='40px' />
                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>BrowserStack</h6>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg={3} sm={6} className="pt-4 h-100">
            <a href="https://buttercms.com/login/?next=/home/" target="_blank">
              <Card className="p-2 card-link">
                <Card.Body>
                  <Image src="icons/buttercms.png" width='40px' />
                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>Butter CMS</h6>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg={3} sm={6} className="pt-4 h-100">
            <a href="https://app.fullstory.com/login/?dest=%2Fui%2F" target="_blank">
              <Card className="p-2 card-link">
                <Card.Body>
                  <Image src="icons/fullstory.png" width='40px' />
                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>Fullstory</h6>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg={3} sm={6} className="pt-4 h-100">
            <a href="https://analytics.google.com/analytics/web/#/report-home/a166691488w241330742p225118943" target="_blank">
              <Card className="p-2 card-link">
                <Card.Body>
                  <Image src="icons/googleanalytics.png" width='40px' />
                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>Google Analytics</h6>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg={3} sm={6} className="pt-4 h-100">
            <a href="https://calendar.google.com/calendar/u/0/r?pli=1" target="_blank">
              <Card className="p-2 card-link">
                <Card.Body>
                  <Image src="icons/googlecalendar.png" width='40px' />
                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>Google Calendar</h6>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg={3} sm={6} className="pt-4 h-100">
            <a href="https://drive.google.com/drive/shared-drives" target="_blank">
              <Card className="p-2 card-link">
                <Card.Body>
                  <Image src="icons/googledrive.png" width='40px' />
                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>Google Drive</h6>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg={3} sm={6} className="pt-4 h-100">
            <a href="https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox" target="_blank">
              <Card className="p-2 card-link">
                <Card.Body>
                  <Image src="icons/googlemail.png" width='40px' />
                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>Gmail</h6>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg={3} sm={6} className="pt-4 h-100">
            <a href="https://id.atlassian.com/login?continue=https%3A%2F%2Fid.atlassian.com%2Fjoin%2Fuser-access%3Fresource%3Dari%253Acloud%253Ajira%253A%253Asite%252Fa74e3212-326b-427e-8a0e-7bad7ddc9894%26continue%3Dhttps%253A%252F%252Fbestpaymentsolutions.atlassian.net&application=jira" target="_blank">
              <Card className="p-2 card-link">
                <Card.Body>
                  <Image src="icons/jira.png" width='40px' />
                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>Jira</h6>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg={3} sm={6} className="pt-4 h-100">
            <a href="https://app.leadfeeder.com/f/sign/in" target="_blank">
              <Card className="p-2 card-link">
                <Card.Body>
                  <Image src="icons/leadfeeder.png" width='40px' />
                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>Leadfeeder</h6>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg={3} sm={6} className="pt-4 h-100">
            <a href="https://www.linkedin.com/feed/" target="_blank">
              <Card className="p-2 card-link">
                <Card.Body>
                  <Image src="icons/linkedin.png" width='40px' />
                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>LinkedIn</h6>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg={3} sm={6} className="pt-4 h-100">
            <a href="https://us14.admin.mailchimp.com/" target="_blank">
              <Card className="p-2 card-link">
                <Card.Body>
                  <Image src="icons/mailchimp.png" width='40px' />
                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>Mailchimp</h6>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg={3} sm={6} className="pt-4 h-100">
            <a href="https://onedrive.live.com/?id=root&cid=3D608E0C3813F24C" target="_blank">
              <Card className="p-2 card-link">
                <Card.Body>
                  <Image src="icons/onedrive.png" width='40px' />
                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>OneDrive</h6>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg={3} sm={6} className="pt-4 h-100">
            <a href="https://app.pipedrive.com/auth/login" target="_blank">
              <Card className="p-2 card-link">
                <Card.Body>
                  <Image src="icons/pipedrive.png" width='40px' />
                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>Pipedrive</h6>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg={3} sm={6} className="pt-4 h-100">
            <a href="https://app.smartsheet.com/b/home?lang=en_US" target="_blank">
              <Card className="p-2 card-link">
                <Card.Body>
                  <Image src="icons/smartsheet.png" width='40px' />
                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>Smarsheet</h6>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg={3} sm={6} className="pt-4 h-100">
            <a href="https://bestpaymentsolutions.zendesk.com/auth/v2/login/signin?return_to=https%3A%2F%2Fbestpaymentsolutions.zendesk.com%2F&theme=hc&locale=1&brand_id=360002583514&auth_origin=360002583514%2Cfalse%2Ctrue" target="_blank">
              <Card className="p-2 card-link">
                <Card.Body>
                  <Image src="icons/zendesk.png" width='40px' />
                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>Zendesk</h6>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg={3} sm={6} className="pt-4 h-100">
            <a href="https://slack.com/intl/en-in/get-started#/createnew" target="_blank">
              <Card className="p-2 card-link">
                <Card.Body>
                  <Image className='m-2' src="icons/slack_logo.jpg" width='40px' />
                  <h6 className='d-inline fw-bold ps-4 bps-midnight-tint'>Slack</h6>
                </Card.Body>
              </Card>
            </a>
          </Col>
        </Row>
      </Container >
    </Container >
  );
}

export default IntranetApps