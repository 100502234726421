import React, { Component, Fragment, useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import IntranetNavigation from "./IntranetNavigation";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import {
  Spin,
  Modal,
  Form,
  Input,
  Select,
  Upload,
  message,
  List,
} from "antd";
import { useMutation, gql, useLazyQuery, useQuery } from "@apollo/client";
import secureLocalStorage from "react-secure-storage";
import "./Intranet.css";
import "../stylingfiles/BPS.css";
import Constants, { rolesArr, roles } from "../Constants";
import DeleteNews from "./DeleteNews";

const { TextArea } = Input;
const { Option } = Select;
const { Dragger } = Upload;

const addnewsmutation = gql`
  mutation AddNews($file: Upload, $input: NewsInput) {
    addNews(file: $file, input: $input) {
      message
      code
    }
  }
`;

const getallnewsquery = gql`
  query GetAllNews($status: Boolean!, $page: Int) {
    getAllNews(status: $status, page: $page) {
      result {
        _id
        title
        description
        isActive
        createdBy
        createdAt
        image
        userId
      }
      totalPages
      totalDocs
      limit
      currentPage
    }
  }
`;

const IntranetHome = () => {
  const [form] = Form.useForm();

  //show hide logic
  const [addShow, setAddShow] = useState(false);

  //error messaging 
  const [messageApi, contextHolder] = message.useMessage();

  //get localstorage data
  const createdBy = secureLocalStorage.getItem(Constants.username);

  //set api response news  
  const [newsDataArr, setNewsData] = useState([]);

  //pagination
  const [newsDataPagination, setNewsDataPagination] = useState(1);

  //file list and validations
  const [fileList, setFilesForList] = useState([]);

  const [fileSizeError, setErrorForFileSize] = useState(false);

  const [fileTypeError, setErrorForFileType] = useState(false);

  const [files, setfileList] = useState();

  const [spinloadingForm, setSpinnerLoadingForm] = useState(false);

  const [spinloadingList, setSpinnerLoadingList] = useState(false);

  const Currentrole = secureLocalStorage.getItem(Constants.userRole);

  const rolesForAddingNews = [roles.ADMINISTRATOR, roles.ROOT];
  
  const  userName = secureLocalStorage.getItem(Constants.username);

  const userId = secureLocalStorage.getItem(Constants.userId);

  const [addnewsHook, { data, loading, error }] = useMutation(addnewsmutation, {
    onCompleted(data) {
      setSpinnerLoadingForm(false);
      form.resetFields();
      if (data.addNews.code === "201") {
        messageApi.open({
          type: 'success',
          content: "News added successfully !",
          duration: 10,
          style: {
            marginTop: "10vh",
          }
        });
        setAddShow(false);
      }
    },
    onError(error) {
      form.resetFields();
      messageApi.open({
        type: 'error',
        content: "Failed to add News",
        duration: 10,
        style: {
          marginTop: "10vh",
        }
      });
      setAddShow(false);
    }
  });



  const calendarLink = process.env.REACT_APP_CALENDARLINK

  const [
    getallnewsHook,
    { data: newsData, loading: newsLoading, error: newsError },
  ] = useLazyQuery(getallnewsquery, {
    onCompleted(data) {


      let paginationDate = {
        pageSize: data.getAllNews.limit,
        total: data.getAllNews.totalDocs,
        current: data.getAllNews.currentPage,
      };
      setNewsDataPagination(paginationDate);

      const dataSource = data.getAllNews.result.map((item) => {
        return {
          title: item.title,
          content: item.description,
          image: item.image,
          id: item._id,
          userId: item.userId
        };
      });
      setNewsData(dataSource);
      setSpinnerLoadingList(false);
    },
    onError(error) {
      console.log(error);
      messageApi.open({
        type: 'error',
        content: "Failed to get News",
        duration: 10,
        style: {
          marginTop: "10vh",
        }
      });
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let page;
    newsDataPagination === null || newsDataPagination === undefined
      ? (page = 1)
      : (page = newsDataPagination.current);

    if (!addShow) {
      setSpinnerLoadingList(true);
      try {
        const getAllnews = async () => {
          const res = await getallnewsHook({
            variables: {
              status: true,
              page: page,
            },
          });
        }
        if (!loading) {
          getAllnews();
        }
      }
      catch (error) {
        setSpinnerLoadingList(false)
        messageApi.open({
          type: 'error',
          content: "Failed to fetch News",
          duration: 10,
          style: {
            marginTop: "10vh",
          }
        });
      }
    }
  }, [newsDataPagination.current, addShow]);

  function onFinish(values) {
    setSpinnerLoadingForm(true)
    const { description, title } = values;
    addnewsHook({
      variables: {
        file: files,
        input: {
          title: title,
          isActive: true,
          description: description,
          createdBy: createdBy,
          userId,
        },
      },
    });
    setNewsDataPagination((prev) => {
      return {
        ...prev,
        current: 1,
      };
    });
    form.resetFields();
    setFilesForList();
    setfileList();
  }

  const getNewsAfterDeleting = async () => {
    let page;
    newsDataPagination === null || newsDataPagination === undefined
      ? (page = 1)
      : (page = newsDataPagination.current);

    await getallnewsHook({
      variables: {
        status: true,
        page: page,
      },
    });
  }

  const handleImage = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const propsUpload = {
    name: "file",
    maxCount: 1,
    accept: "image/jpeg,image/jpg,image/png",
    customRequest: handleImage,
    onChange({ file, fileList, event }) {
      file.size > 10485760
        ? setErrorForFileSize(true)
        : setErrorForFileSize(false);
      file.type !== "image/jpeg" &&
        file.type !== "image/jpg" &&
        file.type !== "image/png" 
        ? setErrorForFileType(true)
        : setErrorForFileType(false);
      setfileList(file.originFileObj);
      let newFileList = [file];
      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url = file.originFileObj.name;
        }
        return file;
      });
      setFilesForList(newFileList);
    },
    onRemove({ file }) {
      setErrorForFileSize(false);
      setErrorForFileType(false);
      setfileList();
      setFilesForList([]);
      let newFileList = [file];
      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url = file.originFileObj.name;
        }
        return file;
      });
      setFilesForList(newFileList);
    },
  };

  return (
    <>
      {contextHolder}
      <Container fluid className="px-0 pb-4 bps-midnight">
        <IntranetNavigation />
        <section className="p-2 pb-0 bps-bg-light">
          <Container fluid className="pt-5 pt-md-2 px-md-5">
            <Row className="px-md-5 pt-5 text-center">
              <Col>
                <h1 className="fw-bold mb-2 bps-midnight">Hi, {userName}</h1>
                <p className="mb-0 bps-midnight-tint">
                  Welcome to the Best Payment Solutions Intranet
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="bps-bg-wave-bottom">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1920"
            height="179"
            viewBox="0 0 1920 179"
          >
            <g transform="translate(2774 7314)">
              <path
                d="M0-30S118.465,0,466.825,0s563.322-60,926.616-60S1920,0,1920,0V97H0Z"
                transform="translate(-2774 -7254)"
                fill="#faf5f0"
                opacity="0.5"
              />
              <path
                d="M0-33S118.465,10,466.825,10s563.322-50,926.616-50S1920,0,1920,0V97H0Z"
                transform="translate(-2774 -7232)"
                fill="#faf5f0"
              />
            </g>
          </svg>
        </div>
        <Container className="pt-5">
          <Row>
            <Col lg={5}>
              <Row className="border-bottom">
                <Col xs={6} md={8}>
                  <h4 className="bps-midnight">News & Events</h4>
                </Col>
                <Col xs={6} md={4} hidden={!rolesForAddingNews.includes(Currentrole)}>
                  <Button
                    className="btn-sm bps-secondary-button-sm float-end"
                    onClick={() => setAddShow(true)}
                  >
                    + Add News
                  </Button>
                  <Modal
                    title="Add news"
                    centered
                    open={addShow}
                    footer={null}
                    onCancel={() => {
                      form.resetFields();
                      setAddShow(false);
                      setFilesForList([]);
                      setfileList();
                    }}
                    style={{
                      marginTop: "10vh",
                    }}
                    onOk={() => {
                      form
                        .validateFields()
                        .then((values) => {
                          onFinish(values);
                        })
                        .catch((info) => {
                          messageApi.open({
                            type: "error",
                            content: info,
                            style: {
                              marginTop: "10vh",
                            },
                          });
                          console.log("Validate Failed:", info);
                        });
                    }}
                  >
                    <Spin spinning={spinloadingForm}>

                    <Form form={form} layout="vertical" onFinish={onFinish}>
                      <Form.Item
                        label="Title"
                        name="title"
                          className="form-label-style"
                        rules={[
                          { required: true, message: "Please enter title" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Description"
                        name="description"
                          className="form-label-style"
                        rules={[
                          {
                            required: true,
                            message: "Please enter description",
                          },
                        ]}
                      >
                          <TextArea
                            style={{ resize: "none" }}
                            rows="7"
                            cols="30"
                          />
                      </Form.Item>
                      <Form.Item
                        name="ImageUpload"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (fileSizeError) {
                                return Promise.reject(
                                  "File size should be less than 10mb"
                                );
                              } else if (fileTypeError) {
                                return Promise.reject(
                                  "Only file types png,jpeg,jpg are allowed"
                                );
                              } else {
                                return Promise.resolve(
                                  "File Uploaded Successfully"
                                );
                              }
                            },
                          }),
                        ]}
                      >
                        <Dragger {...propsUpload} fileList={fileList}>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                            <p>Click or drag pictures here to upload</p>
                        </Dragger>
                      </Form.Item>
                        <Form.Item className="center">
                          <Button
                            className="bps-primary-button mb-2"
                            type="submit"
                          >
                            Add news
                          </Button>
                      </Form.Item>
                    </Form>
                    </Spin>
                  </Modal>
                </Col>
              </Row>
              <Row className="pt-2">
                <Spin spinning={spinloadingList}>
                <List
                  itemLayout="vertical"
                  size="medium"
                  pagination={{
                    ...newsDataPagination,
                    onChange: (page) => {
                      setNewsDataPagination((prev) => {
                        return {
                          ...prev,
                          current: page,
                        };
                      });
                    },
                  }}
                  dataSource={newsDataArr}
                  renderItem={(item) => (
                    <List.Item
                      style={{ padding: "5px 0" }}
                      key={item.title}
                      extra={
                        <React.Fragment>
                          {item.image ?
                            <Image src={item.image} height="auto" className="flex-shrink-1" width={250} />
                            : ''}
                          {((item.userId === userId && Currentrole == roles.ADMINISTRATOR) || (Currentrole === roles.ROOT)) ?
                            <DeleteNews id={item.id} getupdatedNotes={getNewsAfterDeleting}></DeleteNews> : ''}
                        </React.Fragment>
                      }
                    >
                      <List.Item.Meta
                        title={item.title}
                      />
                      {item.content}
                    </List.Item>
                  )}
                  />
                </Spin>
              </Row>
            </Col>
            <Col lg={7} className="ps-lg-4">
              <iframe
                title="calendar"
                src={calendarLink}
                width="800"
                height="600"
                frameBorder="0"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default IntranetHome;
