import React, { useState } from 'react'
import { Popover, message, Tooltip } from "antd";
import { useMutation, gql, useLazyQuery, useQuery } from "@apollo/client";
import {
    Image,
    Button,
} from "react-bootstrap";

const deleteNews = gql`
query DeleteNewsById($newsId: ID!) {
  deleteNewsById(newsId: $newsId) {
    message
    code
  }
}`;

export default function DeleteNews(props) {
    let newsid = props.id;

    const getNewsAfterDelete = props.getupdatedNotes

    const [popovershow, setPopOverShowHide] = useState(false);

    const [messageApi, contextHolder] = message.useMessage();

    const [deleteNewsById] = useLazyQuery(deleteNews);


    function closePopOver() {
        setPopOverShowHide(false);
    }

    function handleOpenChange(newOpen) {
        setPopOverShowHide(newOpen)
    }

    async function DeleteNews() {
        try {
            const res = await deleteNewsById({
                variables: {
                    newsId: newsid
                }
            });
            if (res.data.deleteNewsById.code === "200") {
                messageApi.open({
                    type: 'success',
                    content: "News deleted successfully",
                    duration: 10,
                    style: {
                        marginTop: '10vh',
                    },
                })
                setPopOverShowHide(false);
                setTimeout(() => {
                    getNewsAfterDelete();
                },1000)
            }
        }
        catch (error) {
            messageApi.open({
                type: 'error',
                content: "Error removing news",
                duration: 10,
                style: {
                    marginTop: '10vh',
                },
            })
        }
    }

    return (
        <React.Fragment>
            {contextHolder}
            <Popover
                content={
                    <>
                        <div className="text-center bps-midnight">Delete this News ? </div>
                        <Button className="bps-secondary-button-sm m-1" onClick={DeleteNews}>Delete</Button>
                        <Button className="bps-secondary-button-sm m-1" onClick={closePopOver}>Cancel</Button>
                    </>
                }
                trigger="click"
                open={popovershow}
                onOpenChange={handleOpenChange}
            >
                <Tooltip title="Delete news" placement="bottom">
                    <a className="bps-icon-btn m-1">
                        <Image src="icons/delete.svg" width="20px" />
                    </a>
                </Tooltip>
            </Popover>
        </React.Fragment>
    )
}
