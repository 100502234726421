import React, { Fragment, useEffect, useState } from "react";
import { Form, Input, message } from "antd";
import { useMutation, gql } from "@apollo/client";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import "./Intranet.css";
import "../stylingfiles/BPS.css";
import Constants, { patterns } from "../Constants";

const ResetPassword = gql`
mutation Mutation($input: forgotPasswordReset) {
    forgotPasswordReset(input: $input) {
      message
    }
  }  
`;

const IntranetResetPassword = () => {
    const [form] = Form.useForm();
    const [ResetPasswordMutation] = useMutation(ResetPassword);
    const [messageApi, contextHolder] = message.useMessage();
    const [token, setToken] = useState();
    const redirect = useNavigate();

    const passworderror = <div>A minimum 8 characters password contains a combination of uppercase, lowercase, special character and number are required.</div>

    useEffect(() => {
        let urlArr = window.location.pathname.split('/');
        setToken(urlArr[urlArr.length - 1]);
    }, [])

    const HandleResetPassword = async (values) => {
        const { confirm } = values;
        try {
            const res = await ResetPasswordMutation({
                variables: {
                    input: {
                        password: confirm,
                        token
                    }
                },
            });

            if (res.data) {
                messageApi.open({
                    type: "success",
                    content: res.data.forgotPasswordReset.message,
                    duration: 10,
                });
                setTimeout(() => {
                    redirect('/intranet-signin')
                }, 2000)
            }
        } catch (error) {
            messageApi.open({
                type: "error",
                content: error.message,
            });
        }
    };

    return (
        <>
            {contextHolder}
            <Container fluid className="px-0">
                <Form layout="vertical" form={form} onFinish={HandleResetPassword}>
                    <section className="card-order-platform-bar shadow-sm">
                        <Container fluid>
                            <Row className="justify-content-between align-items-center">
                                <Col>
                                    <a href="/">
                                        <img src="/images/Logo.svg" className="logo" />
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section className="bps-bg-light text-center">
                        <Container fluid className="py-5">
                            <Row className="justify-content-evenly">
                                <Col lg={5}>
                                    <h1 className="fs-48 bps-midnight">Sign In</h1>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section>
                        <Container className="py-5">
                            <Row className="justify-content-center p-4">
                                <Col lg={4} className="bps-card py-5">
                                    <Row className="justify-content-center">
                                        <Col lg={10}>
                                            <Form.Item
                                                name="password"
                                                label="Password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your password!",
                                                    },
                                                    {
                                                        pattern: patterns.passwordCheck,
                                                        message: passworderror,

                                                    }
                                                ]}
                                                hasFeedback
                                            >
                                                <Input.Password />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col lg={10}>
                                            <Form.Item
                                                name="confirm"
                                                label="Confirm Password"
                                                dependencies={["password"]}
                                                hasFeedback
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please confirm your password!",
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (
                                                                !value ||
                                                                getFieldValue("password") === value
                                                            ) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(
                                                                new Error(
                                                                    "The two passwords that you entered do not match!"
                                                                )
                                                            );
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input.Password />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center text-center pt-4">
                                        <Col lg={12}>
                                            <Button type="submit" className="bps-primary-button">
                                                Set Password
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Form>
            </Container>
        </>
    );
};

export default IntranetResetPassword;
