import React, { Component, Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import IntranetNavigation from './IntranetNavigation';
import { Container, Row, Col, Image, Button, Modal, Card } from 'react-bootstrap';
import { Popover, Table } from 'antd';
import './Intranet.css';
import '../stylingfiles/BPS.css';


const IntranetNavigationFavorites = () => {


  return (

    <Container className='pt-5 mt-2 d-none d-sm-block'>
      <Row className='py-2'>
        <Col className="pt-4 text-center">
          <Button type='link' href="https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox" target="_blank" className='fave-link btn-sm mx-1'>
            <Image src="icons/googlemail.png" width='20px' />
            <span className='d-inline fw-bold ps-3 bps-midnight-tint'>Gmail</span>
          </Button>
          <Button type='link' href="https://drive.google.com/drive/shared-drives" target="_blank" className='fave-link btn-sm mx-2'>
            <Image src="icons/googledrive.png" width='20px' />
            <span className='d-inline fw-bold ps-3 bps-midnight-tint'>Google Drive</span>
          </Button>
          <Button type='link' href="https://calendar.google.com/calendar/u/0/r?pli=1" target="_blank" className='fave-link btn-sm mx-2'>
            <Image src="icons/googlecalendar.png" width='20px' />
            <span className='d-inline fw-bold ps-3 bps-midnight-tint'>Google Calendar</span>
          </Button>
          <Button type='link' href="https://app.smartsheet.com/b/home?lang=en_US" target="_blank" className='fave-link btn-sm mx-2'>
            <Image src="icons/smartsheet.png" width='20px' />
            <span className='d-inline fw-bold ps-3 bps-midnight-tint'>Smartsheet</span>
          </Button>
          <Button type='link' href="https://bestpaymentsolutions.zendesk.com/auth/v2/login/signin?return_to=https%3A%2F%2Fbestpaymentsolutions.zendesk.com%2F&theme=hc&locale=1&brand_id=360002583514&auth_origin=360002583514%2Cfalse%2Ctrue" target="_blank" className='fave-link btn-sm mx-2'>
            <Image src="icons/zendesk.png" width='20px' />
            <span className='d-inline fw-bold ps-3 bps-midnight-tint'>Zendesk</span>
          </Button>
          <Button type='link' href="https://id.atlassian.com/login?continue=https%3A%2F%2Fid.atlassian.com%2Fjoin%2Fuser-access%3Fresource%3Dari%253Acloud%253Ajira%253A%253Asite%252Fa74e3212-326b-427e-8a0e-7bad7ddc9894%26continue%3Dhttps%253A%252F%252Fbestpaymentsolutions.atlassian.net&application=jira" target="_blank" className='fave-link btn-sm mx-2'>
            <Image src="icons/jira.png" width='20px' />
            <span className='d-inline fw-bold ps-3 bps-midnight-tint'>Jira</span>
          </Button>
          <Button type='link' href="https://onedrive.live.com/?id=root&cid=3D608E0C3813F24C" target="_blank" className='fave-link btn-sm mx-2'>
            <Image src="icons/onedrive.png" width='20px' />
            <span className='d-inline fw-bold ps-3 bps-midnight-tint'>OneDrive</span>
          </Button>
          <Button type='link' href="https://bestpayment.solutions/" target="_blank" className='fave-link btn-sm mx-2'>
            <Image src="icons/Logomark - Color.png" width='20px' />
            <span className='d-inline fw-bold ps-3 bps-midnight-tint'>BPS Corporate Site</span>
          </Button>
          <Button type='link' href="https://slack.com/intl/en-in/get-started#/createnew" target="_blank" className='fave-link btn-sm mx-2'>
            <Image src="icons/slack_logo.jpg" width='20px' />
            <span className='d-inline fw-bold ps-3 bps-midnight-tint'>Slack</span>
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default IntranetNavigationFavorites