import React from 'react';
import './index.css';
import App,{client} from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';

const container = document.getElementById("root");
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
          <App />
    </ApolloProvider>
  </React.StrictMode>
)
