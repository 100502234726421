import { gql, useLazyQuery } from "@apollo/client";
import { message, Popover } from "antd";
import React, { useState } from "react";
import { Button, Image } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import Constants, { roles } from "../../Constants";


const deleteClientGql = gql`
  query DeleteClientById($clientId: ID!) {
    deleteClientById(clientID: $clientId) {
      message
      code
    }
  }
`;

const DeleteClient = ({ clientId, getClients }) => {
  const [popovershow, setPopOverShowHide] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [deleteClient] = useLazyQuery(deleteClientGql);
  const showDeleteClient = [roles.ROOT];
  const currentRole = secureLocalStorage.getItem(Constants.userRole);

  function closePopOver() {
    setPopOverShowHide(false);
  }

  function handleOpenChange(newOpen) {
    setPopOverShowHide(newOpen);
  }

  async function deleteClientById() {
    try {
      const res = await deleteClient({
        variables: {
          clientId,
        },
      });
      if (res.data.deleteClientById.code === "200") {
        messageApi.open({
          type: "success",
          content: "Client removed successfully",
          duration: 10,
          style: {
            marginTop: "10vh",
          },
        });
        setPopOverShowHide(false);
        setTimeout(() => {
          getClients({
            variables: {
              filter: ["true"],
            },
          });
        }, 1000);

      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error removing Client",
        duration: 10,
        style: {
          marginTop: "10vh",
        },
      });
    }
  }


  return (
    <>
      <div>
        {contextHolder}
       
          <Popover
            content={
              <>
                <div className="text-center bps-midnight">
                  Wish to remove this client?
                </div>
                <Button className="bps-secondary-button-sm m-1" onClick={deleteClientById}>
                  Delete
                </Button>
                <Button className="bps-secondary-button-sm m-1" onClick={closePopOver}>
                  Cancel
                </Button>
              </>
            }
            trigger="click"
            open={popovershow}
            onOpenChange={handleOpenChange}
          >
            <Popover
              content={<div className="text-center bps-midnight">Delete</div>}
              trigger="hover"
            >
              <a hidden={!showDeleteClient.includes(currentRole)} className="bps-icon-btn m-1" >
                <Image src="icons/delete.svg" width="20px" />
              </a>
            </Popover>
          </Popover>
      </div>
    </>
  );
};

export default DeleteClient;
