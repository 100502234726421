import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export default function dateToPST(date) {
  if (date !== null && date !== undefined && date !== "") {
    const dateTime = dayjs;

    const dateObj = dateTime(date);

    const formattedDate = dateObj.format("MM/DD/YYYY");

    return formattedDate;
  } else {
    return "";
  }
}
