import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import { Popover, Upload, Table, Image } from 'antd';
import '../Intranet.css';


const SolutionsFest2021
    = () => {
        return (

            <Container fluid className="pb-4 px-0">
                <Row>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2021/sf2021-01.jpeg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2021/sf2021-02.jpeg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2021/sf2021-04.jpeg' />
                    </Col>
                    <Col xs={6} md={3} className='py-2'>
                        <Image src='./images/SolutionsFest2021/sf2021-04.jpeg' />
                    </Col>
                </Row>
            </Container>
        );
    }

export default SolutionsFest2021;