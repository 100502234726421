import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {

  Input,
  message,
  Modal,
  Radio,
  Spin,
  Form,
  Popover,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Constants, { patterns, roles } from "../../Constants";
import { Row, Col, Image } from "react-bootstrap";

const GET_ALL_CLIENTS = gql`
  query GetAllClientWithCompleteDetails($filter: [String]!) {
    getAllClientWithCompleteDetails(filter: $filter) {
      client {
        _id
        name
        image
        type
        isActive
        createdBy
      }
      program {
        _id
        name
        header {
          label
          value
        }
        isActive
        createdBy
        client
        programView {
          _id
          name
          stagingSite
          liveSite
          isActive
          createdBy
          program
          notes {
            _id
            note
            image
            isActive
            createdBy
            userId
            createdAt
            programView
          }
        }
      }
    }
  }
`;

const getClientById = gql`
  query GetClientById($id: ID!) {
    getClientById(_id: $id) {
      _id
      name
      image
      type
      isActive
      createdBy
    }
  }
`;

const EDIT_CLIENT = gql`
  mutation AddClient($input: ClientInput, $file: Upload) {
    addClient(input: $input, file: $file) {
      message
      code
    }
  }
`;

export default function EditClientForm(props) {
  const clientId = props.clientId;
  const [form] = Form.useForm();
  const [fileList, setFilesForList] = useState([]);
  const [fileSizeError, setErrorForFileSize] = useState(false);
  const [fileTypeError, setErrorForFileType] = useState(false);
  const [files, setfileList] = useState();
  const createdBy = secureLocalStorage.getItem(Constants.username);
  const [spinloadingForm, setSpinloadingForm] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [visible, setVisible] = useState(false);
  const showEditClient = [roles.ROOT];
  const currentRole = secureLocalStorage.getItem(Constants.userRole);
  const [clientData, setClientData] = useState();

 

  useEffect(() => {
    if (visible) {
      let imageurl=clientDataById.getClientById.image.split('/');
      let imagename=imageurl[imageurl.length-1];
      setFilesForList([
        {
          uid: "-1",
          status: "done",
          name:imagename,
          url: clientDataById.getClientById.image,
        },
      ]);
    }
  }, [visible]);

  const [
    getClientDataById,
    {
      data: clientDataById,
      loading: clientdataloading,
      error: errorGettingClientData,
    },
  ] = useLazyQuery(getClientById, {
    variables: {
      id: clientId,
    },
    fetchPolicy:'network-only'
  });

  const showModal = () => {
    if(clientDataById){
      setVisible(true);
    }
  };

  const [editClient] = useMutation(EDIT_CLIENT, {
    onCompleted(data) {
      setSpinloadingForm(false);
      setfileList();
      setFilesForList();
      setErrorForFileType();
      setErrorForFileSize();
      if (data.addClient.code === "200") {
        messageApi.open({
          type: "success",
          content: "Client information edited successfully !",
          duration: 5,
          style: {
            marginTop: "10vh",
          },
        });
        setTimeout(()=>{
          form.resetFields();
            setVisible(false);
        },1000)
      }
    },
    onError(error) {
      setVisible(false);
      setSpinloadingForm(false);
      messageApi.open({
        type: "error",
        content: "Failed to edit client information",
        duration: 5,
        style: {
          marginTop: "10vh",
        },
      });
      setfileList();
      setFilesForList();
      setErrorForFileType();
      setErrorForFileSize();
      form.resetFields();
      console.log(error);
    },
    refetchQueries: [GET_ALL_CLIENTS],
  });

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
    setfileList();
    setFilesForList();
    setErrorForFileType();
    setErrorForFileSize();
  };

  const handleImage = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const propsUpload = {
    name: "file",
    maxCount: 1,
    listType: "picture",
    accept: "image/jpeg,image/jpg,image/png",
    showUploadList: {
      showRemoveIcon: false,
    },
    customRequest: handleImage,
    onChange({ file, fileList, event }) {
      file.size > 10485760
        ? setErrorForFileSize(true)
        : setErrorForFileSize(false);
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg" &&
      file.type !== "image/png"
        ? setErrorForFileType(true)
        : setErrorForFileType(false);
      setfileList(file.originFileObj);
      let newFileList = [file];
      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url = file.originFileObj.name;
        }
        return file;
      });
      setFilesForList(newFileList);
    },
    onRemove({ file }) {
      setErrorForFileSize(false);
      setErrorForFileType(false);
      setfileList();
      setFilesForList([]);
      let newFileList = [file];
      newFileList = newFileList.map((file) => {
        if (file?.response) {
          file.url = file.originFileObj.name;
        }
        return file;
      });
      setFilesForList(newFileList);
    },
  };

  const editClientDetails = (values) => {
    setSpinloadingForm(true);
    let clientData = {
      input: {
        _id: clientId,
        name: values.clientName,
        type: values.clientType,
        isActive: true,
        createdBy,
      },
      file: files,
    };
    if (files === undefined) {
      delete clientData["file"];
    }
    editClient({
      variables: clientData,
    });
  };

  return (
    <>
      {contextHolder}
      <Popover
        content={<div className="text-center bps-midnight">Edit</div>}
        trigger="hover"
      >
        <a
          hidden={!showEditClient.includes(currentRole)}
          className="bps-icon-btn m-1"
          onClick={() => {
            getClientDataById();
            showModal();
          }}
        >
          <Image src="icons/edit.svg" width="20px" />
        </a>
      </Popover>
      <Modal
        title="Edit Client"
        open={visible}
        width={500}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              editClientDetails(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        onCancel={() => {
          handleCancel();
        }}
        cancelButtonProps={{
          display: "none",
        }}
      >
        <Spin spinning={spinloadingForm}>
          <Form
            name="clientForm"
            layout="vertical"
            onFinish={editClientDetails}
            initialValues={{
              clientName: clientDataById?.getClientById.name,
              clientType: clientDataById?.getClientById.type,
            }}
            form={form}
          >
            <Row>
              <Col>
                <Form.Item
                  name="clientName"
                  label="Client Name"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Client's Name",
                    },
                    {
                      pattern: patterns.namePattern,
                      message:
                        "Client name can only start with an alphabetic character",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="clientType"
                  label="Client Type"
                  rules={[
                    {
                      required: true,
                      message: "Client Type is Required",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="EXTERNAL">External</Radio>
                    <Radio value="INTERNAL">Internal</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item
                name="clientLogo"
                label="Client Logo"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (fileSizeError) {
                        return Promise.reject(
                          "File size should be less than 10mb"
                        );
                      } else if (fileTypeError) {
                        return Promise.reject(
                          "Only file types png,jpeg,jpg are allowed"
                        );
                      } else {
                        return Promise.resolve("File Uploaded Successfully");
                      }
                    },
                  }),
                ]}
              >
                <Dragger {...propsUpload} fileList={fileList}>
                  <p className="ant-upload-text">
                    Click or drag your logo to this area to upload
                  </p>
                </Dragger>
              </Form.Item>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}
