import React, { useState, useEffect } from "react";
import { Container, Image, Button } from "react-bootstrap";
import {
  Popover,
  Upload,
  Table,
  Modal,
  Form,
  Input,
  message,
  Spin,
} from "antd";
import { useMutation, gql, useLazyQuery, useQuery } from "@apollo/client";
import { InboxOutlined } from "@ant-design/icons";
import secureLocalStorage from "react-secure-storage";
import Constants, { roles } from "../../Constants";
import DataTable from "../../common/DataTable";
import "../Intranet.css";
import { onError } from "@apollo/client/link/error";
import DeleteNote from "../DeleteNote";

const { Dragger } = Upload;
const { TextArea } = Input;
const userName = secureLocalStorage.getItem(Constants.username);
const role = secureLocalStorage.getItem(Constants.userRole);
const userId = secureLocalStorage.getItem(Constants.userId);

const addNote = gql`
  mutation AddNote($file: Upload, $input: NoteInput) {
    addNote(file: $file, input: $input) {
      message
      code
    }
  }
`;

const getALlnotes = gql`
  query GetAllNotes($status: [String]!, $page: Int) {
    getAllNotes(status: $status, page: $page) {
      result {
        _id
        note
        image
        isActive
        createdBy
        userId
        createdAt
        programView
      }
      totalPages
      totalDocs
      limit
      currentPage
    }
  }
`;


const PastAddButtons = (props) => {
  let { programviewid ,getClients} = props;

  const [programViewId, setProgramViewId] = useState(programviewid);

  const [pastShow, setPastShow] = useState(false);

  const [addShow, setAddShow] = useState(false);

  const [imageShow, setImageShow] = useState(false);

  const [form] = Form.useForm();

  const [messageApi, contextHolder] = message.useMessage();

  const [currentPagePastNotes, setCurrentPageForPastNotes] = useState(1);

  const [pastNotesData, setPastNotesData] = useState();

  const [totalPages, setTotalPages] = useState();

  //file list and validations
  const [fileList, setFilesForList] = useState([]);

  const [fileSizeError, setErrorForFileSize] = useState(false);

  const [fileTypeError, setErrorForFileType] = useState(false);

  const [files, setfileList] = useState();

  const [spinloadingForm, setSpinnerLoadingForm] = useState(false);

  const [spinloadingTable, setSpinnerLoadingTable] = useState(false);

  const currentUserName = secureLocalStorage.getItem(Constants.username);

  const rolesForAddNote = [roles.ROOT, roles.ADMINISTRATOR];
  useEffect(() => {
    setSpinnerLoadingTable(true);
    if (pastShow) {
      GetAllNotesFunction({
        variables: {
          status: ["true", programviewid],
          page: currentPagePastNotes,
        },
      });
    }
  }, [pastShow, currentPagePastNotes]);

  const [AddNoteFunction] = useMutation(addNote, {
    onCompleted(data) {
      if (data.addNote.code === "201") {
        messageApi.open({
          type: "success",
          content: "Note added successfully !",
          duration: 10,
          style: {
            marginTop: "10vh",
          },
        });
      }
      setSpinnerLoadingForm(false);
      form.resetFields();
      setAddShow(false);
       getClients({
         variables: {
           filter: ["true"],
         },
       });
          setfileList();
          setFilesForList();
          setErrorForFileType();
          setErrorForFileSize();
    },
    onError(error) {
      messageApi.open({
        type: "error",
        content: "Failed to add note",
        duration: 10,
        style: {
          marginTop: "10vh",
        },
      });
      setSpinnerLoadingForm(false);
      console.log(error);
      setAddShow(false);
      form.resetFields();
      setfileList();
      setFilesForList();
    },
  });

  const [GetAllNotesFunction] = useLazyQuery(getALlnotes, {
    onCompleted(data) {
      let resultarr = data.getAllNotes.result;
      setCurrentPageForPastNotes(data.getAllNotes.currentPage);
      setTotalPages({
        currentPage: data.getAllNotes.currentPage,
        limit: data.getAllNotes.limit,
        totalDocs: data.getAllNotes.totalDocs,
      });
      const PastNotesData = resultarr.map((item) => {
        return {
          _id: item._id,
          note: item.note,
          image: item?.image,
          isActive: item.isActive,
          createdBy: item.createdBy,
          createdAt: item.createdAt,
          programView: item.programView,
          userId: item.userId,
        };
      });

      setPastNotesData(PastNotesData);
      setSpinnerLoadingTable(false);
    },
    fetchPolicy: "network-only",
  });

  async function getNotesAfterDelete() {
    GetAllNotesFunction({
      variables: {
        status: ["true", programviewid],
        page: currentPagePastNotes,
      },
    });
  }

  async function onFinishAddNote(values) {
    setSpinnerLoadingForm(true);
    let { note } = values;

    try {
      const res = await AddNoteFunction({
        variables: {
          file: files,
          input: {
            programView: programviewid,
            note: note,
            isActive: true,
            userId,
            createdBy: currentUserName,
          },
        },
      });
    } catch (error) {
      setSpinnerLoadingForm(false);
    }
  }

  const handleImage = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const propsUpload = {
    name: "file",
    maxCount: 1,
    accept: "image/jpeg,image/jpg,image/png",
    customRequest: handleImage,
    onChange({ file, fileList, event }) {
      file.size > 10485760
        ? setErrorForFileSize(true)
        : setErrorForFileSize(false);
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg" &&
      file.type !== "image/png"
        ? setErrorForFileType(true)
        : setErrorForFileType(false);
      setfileList(file.originFileObj);
      let newFileList = [file];
      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url = file.originFileObj.name;
        }
        return file;
      });
      setFilesForList(newFileList);
    },
    onRemove({ file }) {
      setErrorForFileSize(false);
      setErrorForFileType(false);
      setfileList();
      setFilesForList([]);
      let newFileList = [file];
      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url = file.originFileObj.name;
        }
        return file;
      });
      setFilesForList(newFileList);
    },
  };

  function handleAddShow() {
    setfileList();
    setFilesForList();
    setAddShow(false);
    form.resetFields();
  }

  const columns = [
    {
      title: "NOTE",
      dataIndex: "note",
      key: "note",
      className: "text-secondary",
    },
    {
      title: "IMAGE",
      dataIndex: "image",
      key: "image",
      className: "text-secondary",
      render: (text, record) =>
        text !== "" && text !== null && text !== undefined ? (
          <a href={text} target="_blank" className="bps-link-button" download>
            Download
          </a>
        ) : (
          ""
        ),
    },
    {
      title: "DATE CREATED",
      dataIndex: "createdAt",
      key: "date",
      className: "text-secondary",
      render: (text) => {
        return new Date(parseInt(text)).toLocaleDateString("en-US");
      },
    },
    {
      title: "CREATED BY",
      dataIndex: "createdBy",
      key: "created",
      className: "text-secondary",
    },
    {
      dataIndex: "_id",
      key: "_id",
      render: (text, record) => {
        return (record.userId === userId && role === roles.ADMINISTRATOR) ||
          role === roles.ROOT ? (
          <DeleteNote id={text} getupdatedNotes={getNotesAfterDelete} />
        ) : (
          ""
        );
      },
    },
  ];

  return (
    <React.Fragment>
      {contextHolder}
      <Container
        fluid
        className="border-start px-0 d-flex flex-row justify-content-center align-items-center m-1"
      >
        <Popover
          content={<div className="text-center bps-midnight">Past Notes</div>}
          trigger="hover"
        >
          <a className="bps-icon-btn ms-4" onClick={() => setPastShow(true)}>
            <Image src="icons/pastnotes.svg" width="20px" />
          </a>
        </Popover>

        <Modal
          title="Past Notes"
          centered
          footer={null}
          onOk={() => {
            setPastShow(false);
            getClients({
              variables: {
                filter: ["true"],
              },
            });
          }}
          open={pastShow}
          onCancel={() => {
            setPastShow(false);
            getClients({
              variables: {
                filter: ["true"],
              },
            });
          }}
          responsive={true}
          width={"60vw"}
        >
          <Spin spinning={spinloadingTable}>
            <DataTable
              showCheck={false}
              totalPages={totalPages}
              currentPage={(cp) => {
                setCurrentPageForPastNotes(cp);
              }}
              columnData={columns}
              tableData={pastNotesData}
              scrollX={600}
              className="border p-2"
            />
          </Spin>
        </Modal>

        <Popover
          content={<div className="text-center bps-midnight">Add Notes</div>}
          trigger="hover"
        >
          <a
            hidden={!rolesForAddNote.includes(role)}
            className="bps-icon-btn my-1"
            onClick={() => setAddShow(true)}
          >
            <Image src="icons/addnotes.svg" width="20px" />
          </a>
        </Popover>

        <Modal
          title="Add Note"
          centered
          open={addShow}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                onFinishAddNote(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
          onCancel={handleAddShow}
          footer={null}
        >
          <Spin spinning={spinloadingForm}>
            <Form form={form} onFinish={onFinishAddNote} layout="vertical">
              <Form.Item
                name="note"
                rules={[
                  { required: true, message: "Please enter your note !" },
                ]}
              >
                <TextArea
                  style={{ resize: "none" }}
                  rows="7"
                  cols="30"
                  placeholder="Enter your note here"
                />
              </Form.Item>
              <Form.Item
                name="ImageUpload"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (fileSizeError) {
                        return Promise.reject(
                          "File size should be less than 10mb"
                        );
                      } else if (fileTypeError) {
                        return Promise.reject(
                          "Only file types png,jpeg,jpg are allowed"
                        );
                      } else {
                        return Promise.resolve("File Uploaded Successfully");
                      }
                    },
                  }),
                ]}
              >
                <Dragger {...propsUpload} fileList={fileList}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">Only support for one file.</p>
                </Dragger>
              </Form.Item>

              <Form.Item className="center">
                <Button className="bps-primary-button mt-4" type="submit">
                  Publish Note
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </Container>
    </React.Fragment>
  );
};

export default PastAddButtons;
