import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import { Popover, Table } from "antd";
import PastAddButtons from "./PastAddButtons";
import Note from "./Note";
import ImageDeleteButtons from "./ImageDeleteButtons";
import AddProgramForm from "./AddProgramForm";
import DeleteProgram from "./DeleteProgram";
import EditProgramForm from "./EditProgramForm";

const content = (
  <div>
    <p className="fw-bold mb-0">Heads Up!</p>
    <p>
      You are entering the{" "}
      <span className="fw-bold uppercase">Live Website</span>.
    </p>
  </div>
);

const columns = [
  {
    title: "PROGRAM",
    dataIndex: "program",
    key: "program",
    className: "fw-bold text-secondary",
    fixed: "left",
    width: "10%",
  },
  {
    title: "STAGING SITE",
    dataIndex: "staging",
    key: "staging",
    className: "bps-sky ps-2 bps-bg-light-sky",
    width: "20%",
  },
  {
    title: "LIVE SITE",
    dataIndex: "live",
    key: "live",
    className: "bps-live ",
    width: "16%",
  },

  {
    title: "NOTES",
    dataIndex: "notes",
    key: "notes",
    className: "text-secondary",
    width: "24%",
  },
  {
    title: "",
    dataIndex: "imagedeletebuttons",
    key: "imagedeletebuttons",
    className: "text-end",
    width: "6%",
  },
  {
    title: "",
    dataIndex: "pastaddbuttons",
    key: "pastaddbuttons",
    className: "text-end",
    width: "6%",
  },
];

const ClientCard = ({ client, getClients }) => {
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    setPrograms(client.program);
  }, [client]);

  return (
    <>
      <Container fluid className="pb-4 px-0">
        <Row>
          <Col>
            {client.client.image ? (
              <Image src={client.client.image} height="60px" />
            ) : (
              ""
            )}
          </Col>
          <Col>
            <AddProgramForm getClients={getClients} client={client} />
          </Col>
        </Row>
        {programs.length > 0 &&
          programs.map((program) => {
            const programData = [];
            program.programView.forEach((view, index) => {
              if(view.isActive){
                const rowData = {
                  key: index,
                  program: view.name == "Admin" ? "ADMIN SITE" : "CONSUMER SITE",
                  staging: (
                    <a
                      href={view.stagingSite}
                      target="_blank"
                      className="staging-link"
                    >
                      {view.stagingSite}
                    </a>
                  ),
                  live: view.liveSite && (
                    <Popover
                      content={
                        <div className="text-center small bps-midnight pb-2">
                          {content}
                          <a
                            href={view.liveSite}
                            target="_blank"
                            className="bps-primary-button-sm"
                          >
                            Continue to Live Site
                          </a>
                        </div>
                      }
                      trigger="click"
                    >
                      <a className="live-link">{view.liveSite}</a>
                    </Popover>
                  ),
                  notes:
                    view.notes !== null &&
                    view.notes !== undefined &&
                    view.notes.length > 0
                      ? view.notes[0].isActive === true && (
                          <Note note={view.notes[0].note} />
                        )
                      : "",
                  imagedeletebuttons:
                    view.notes !== null &&
                    view.notes !== undefined &&
                    view.notes.length > 0
                      ? ( view.notes[0].isActive === true && view.notes[0].image !== "" )&&(
                          <ImageDeleteButtons image={view.notes[0].image} />
                        )
                      : "",
                  pastaddbuttons: (
                    <PastAddButtons
                      programviewid={view._id}
                      getClients={getClients}
                    />
                  ),
                };
  
                programData.push(rowData);
              }
            });

            return (
              <>
                {program.isActive && (
                  <Card className="mt-4">
                    <Card.Header className="pb-0">
                      <Row className="border-bottom pb-0">
                        <Col>
                          <table className="table table-sm bps-midnight mb-0">
                            <thead className="d-flex justify-content-between align-items-center">
                              <th className="align-bottom">
                                <h4 className="fw-bolder pt-2 bps-midnight">
                                  {program.name}
                                </h4>
                              </th>
                              <div className="d-flex  align-items-center">
                                <DeleteProgram
                                  getallclients={getClients}
                                  programId={program._id}
                                />
                                <EditProgramForm
                                  getallclients={getClients}
                                  programId={program._id}
                                  clientId={client.client._id}
                                />
                              </div>
                            </thead>
                          </table>
                        </Col>
                      </Row>
                      {program.header && program.header.length > 0 && (
                        <Row className="pt-2">
                          <Col>
                            <div className="table-responsive">
                              <table className="table table-sm table-borderless text-secondary">
                                <tbody>
                                  <tr>
                                    {program.header.map((header, index) => {
                                      return (
                                        <th key={index}>
                                          {header.label != null
                                            ? header.label
                                            : ""}
                                          <span className="fw-normal d-block">
                                            {header.value != null
                                              ? header.value
                                              : ""}
                                          </span>
                                        </th>
                                      );
                                    })}
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Card.Header>
                    <Card.Body>
                      <Table
                        scroll={{ x: 1000 }}
                        columns={columns}
                        dataSource={programData}
                        pagination={false}
                      />
                    </Card.Body>
                  </Card>
                )}
              </>
            );
          })}
      </Container>
    </>
  );
};

export default ClientCard;
