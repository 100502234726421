import React, { Fragment, useEffect, useState } from "react";
import {
    Container,
    Image,
} from "react-bootstrap";
import { Popover, Modal, Form, Input, Select, message, Spin } from "antd";
import secureLocalStorage from "react-secure-storage";
import { useMutation, gql, useLazyQuery, useQuery } from "@apollo/client";
import Constants, { userStatus } from '../Constants';
import { rolesArr } from "../Constants";
import "./Intranet.css";

const editUserDetails = gql`
mutation UpdateUserDetails($input: UpdateInput) {
  updateUserDetails(input: $input) {
    message
    code
  }
}
`;

const getUserById = gql`query GetUserById($userId: ID!) {
    getUserById(userId: $userId) {
        username
        name
        email
        id
        userRole
        phone
        isActive
        createdAt
        registeredDate
        createdBy
    }
}`;

const EditDeleteButtons = (props) => {
    const getUpdatedUsers = props.getUserCall;
    const [popovershow, setPopOverShowHide] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [spinnerloading, setLoading] = useState(false);
    const [editUser] = useMutation(editUserDetails);
    const [editShow, setEditShow] = useState(false);
    const [disable, setDisable] = useState(false);
    const [form] = Form.useForm();
    const { userData } = props;
    const { Option } = Select;
    const createdBy = secureLocalStorage.getItem(Constants.username);

    const [getuserbyid, { data, loading, error }] = useLazyQuery(getUserById, {
        onCompleted(data) {
            if (data.getUserById.registeredDate !== null && data.getUserById.registeredDate !== "" && data.getUserById.registeredDate !== undefined) {
                setDisable(true);
            }
            else {
                setDisable(false);
            }
            form.setFieldsValue({ name: data.getUserById.name, email: data.getUserById.email, role: data.getUserById.userRole, status: data.getUserById.isActive });
        },
        fetchPolicy: "network-only",

    });

    async function getuserbyId() {
        try {
            setLoading(true);
            await getuserbyid({
                variables: {
                    userId: userData,
                }
            })
            setLoading(false);
            setEditShow(true)
        }
        catch (err) {
            setLoading(false);
            console.log(error);
        }
    }

    const onFinish = async (values) => {
        setLoading(true);
        const { name, email, role, status } = values;
        try {
            const res = await editUser({
                variables: {
                    input: {
                        _id: userData,
                        createdBy,
                        email,
                        name,
                        username: name,
                        userRole: role,
                        isActive: status,
                    },
                },
            });
            if (res.data.updateUserDetails.code === "200") {
                messageApi.open({
                    type: 'success',
                    content: "User details updated successfully",
                    duration: 10,
                    style: {
                        marginTop: '10vh',
                    },
                });
                setLoading(false);
                form.resetFields();
                setEditShow(false);
                getUpdatedUsers();
            }
        } catch (err) {
            setLoading(false);
            messageApi.open({
                type: 'error',
                content: err.message,
                duration: 10,
                style: {
                    marginTop: '10vh',
                },
            });
        }
    };

    function closePopOver() {
        setPopOverShowHide(false);
    }

    function handleOpenChange(newOpen) {
        setPopOverShowHide(newOpen)
    }

    return (
        <>
            {contextHolder}
            <Spin spinning={spinnerloading}>

                <Container fluid className="px-0">
                    {/* The Image button should only appear for notes that have an image attached. */}
                    <Popover
                        content={<div className="text-center bps-midnight">Edit</div>}
                        trigger="hover"
                    >
                        <a className="bps-icon-btn m-1" onClick={() => {
                            getuserbyId();

                        }}>
                            <Image src="icons/edit.svg" width="20px" />
                        </a>
                    </Popover>
                    <Modal
                        title="Edit user"
                        centered
                        open={editShow}
                        onCancel={() => setEditShow(false)}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    onFinish(values);
                                })
                                .catch((info) => {
                                    console.log("Validate Failed:", info);
                                });
                        }}
                    >
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Form.Item
                                label="Full name"
                                name="name"
                                className="form-label-style"
                                rules={[
                                    { required: true, message: "Please input your full name!" },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Email"
                                name="email"
                                className="form-label-style"
                                rules={[{ required: true, message: "Please input your email!" }, {
                                    type: 'email', message: "Please enter a valid Email"
                                }]}
                            >
                                <Input disabled={disable} />
                            </Form.Item>
                            <Form.Item
                                label="Role"
                                name="role"
                                className="form-label-style"
                                rules={[{ required: true }]}
                            >
                                <Select>
                                    {rolesArr.map((role) => (
                                        <Option key={role.name} value={role.value}>
                                            {role.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Status"
                                name="status"
                                className="form-label-style"
                                rules={[{ required: true }]}
                            >
                                <Select>
                                    {userStatus.map((status) => (
                                        <Option key={status.name} value={status.value}>
                                            {status.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Form>
                    </Modal>
                </Container>
            </Spin>
        </>
    );
};

export default EditDeleteButtons;
