import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import { Popover, Table, Modal, Form, Input, message, Spin, Tooltip } from "antd";
import React, { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Constants, { patterns, roles } from "../../Constants";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const EDIT_PROGRAM = gql`
  mutation BuildProgram($input: ProgramBuilder) {
    buildProgram(input: $input) {
      message
      code
    }
  }
`;

const DELETE_PROGRAM_VIEW = gql`
  query DeleteProgramViewById($programViewId: ID!) {
    deleteProgramViewById(programViewId: $programViewId) {
      message
      code
    }
  }
`;

const GET_ALL_CLIENTS = gql`
  query GetAllClientWithCompleteDetails($filter: [String]!) {
    getAllClientWithCompleteDetails(filter: $filter) {
      client {
        _id
        name
        image
        type
        isActive
        createdBy
      }
      program {
        _id
        name
        header {
          label
          value
        }
        isActive
        createdBy
        client
        programView {
          _id
          name
          stagingSite
          liveSite
          isActive
          createdBy
          program
          notes {
            _id
            note
            image
            isActive
            createdBy
            userId
            createdAt
            programView
          }
        }
      }
    }
  }
`;

const GET_PROGRAM = gql`
  query GetProgramById($id: ID!) {
    getProgramById(_id: $id) {
      _id
      name
      header {
        label
        value
      }
      isActive
      createdBy
      client
      programView {
        _id
        name
        stagingSite
        liveSite
        isActive
        createdBy
        program
        notes {
          _id
          note
          image
          isActive
          createdBy
          userId
          createdAt
          programView
        }
      }
    }
  }
`;

const EditProgramForm = ({ getClients, programId, clientId }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [program, setProgram] = useState({});
  const [spinloadingForm, setSpinloadingForm] = useState(false);
  const [popoverKey, setPopoverKey] = useState();
  const [popovershow, setPopOverShowHide] = useState(false);
  const createdBy = secureLocalStorage.getItem(Constants.username);
  const currentRole = secureLocalStorage.getItem(Constants.userRole);
  const showProgramEdit = [roles.ROOT];

  const urlValidator = (rule, value, callback) => {
    if (value) {
      const urlPattern = patterns.websiteLinkValidationPattern;
      if (!urlPattern.test(value)) {
        callback("Please provide a valid site link");
      } else {
        const url = new URL(value);
        if (!url.hostname || url.hostname === "localhost") {
          callback("Please enter a valid domain");
        } else {
          callback();
        }
      }
    } 
    else {
      return Promise.resolve();
    }
  };

  const [editProgram] = useMutation(EDIT_PROGRAM, {
    onCompleted(data) {
      if (data.buildProgram.code === "201") {
        messageApi.open({
          type: "success",
          content: "Program edited successfully!",
          duration: 5,
          style: {
            marginTop: "10vh",
          },
        });
      }
    },
    onError(error) {
      messageApi.open({
        type: "error",
        content: "Failed to edit program",
        duration: 5,
        style: {
          marginTop: "10vh",
        },
      });
      console.log(error);
    },
    refetchQueries: [GET_ALL_CLIENTS],
  });

  const [getProgramDetails, { data, loading, error }] = useLazyQuery(
    GET_PROGRAM,
    {
      onCompleted(data) {
        setProgram(data.getProgramById);
        let headerObj = {};
        let Consumer = [];
        let Admin = [];

        data.getProgramById.header.forEach((header) => {
          switch (header.label) {
            case "Customer Service Phone":
              headerObj = { ...headerObj, customerServicePhone: header.value };
              break;
            case "Customer Service Email":
              headerObj = { ...headerObj, customerServiceEmail: header.value };
              break;
            case "Direct Customer Service Phone":
              headerObj = {
                ...headerObj,
                directCustomerServicePhone: header.value,
              };
              break;
            case "Email Inbox":
              headerObj = { ...headerObj, emailInbox: header.value };
              break;
            case "Direct Customer Service Email":
              headerObj = {
                ...headerObj,
                directCustomerServiceEmail: header.value,
              };
              break;
            case "Zendesk Outbound Phone":
              headerObj = { ...headerObj, zendeskOutboundPhone: header.value };
              break;
          }
        });

        data.getProgramById.programView.forEach((view) => {
          if( view.isActive ){

            if ( view.name === "Consumer" ) {
              let newConsumerObj = {
                consumerStagingSite: view.stagingSite,
                consumerLiveSite: view.liveSite,
                _id: view._id,
              };
              Consumer.push(newConsumerObj);
            } else {
              let newAdminObj = {
                adminStagingSite: view.stagingSite,
                adminLiveSite: view.liveSite,
                _id: view._id,
              };
              Admin.push(newAdminObj);
            }

          }

        });
        form.setFieldsValue({
          programName: data.getProgramById.name,
          ...headerObj,
          Consumer,
          Admin,
        });
      },
      onError(err) {
        console.log(err);
      },
      fetchPolicy: "network-only",
    }
  );

  const editProgramById = (values) => {
    let header = [];
    let programView = [];

    if (
      values.customerServicePhone !== null &&
      values.customerServicePhone !== undefined &&
      values.customerServicePhone !== ""
    ) {
      header.push({
        label: "Customer Service Phone",
        value: values.customerServicePhone,
      });
    }
    if (
      values.customerServiceEmail !== null &&
      values.customerServiceEmail !== undefined &&
      values.customerServiceEmail !== ""
    ) {
      header.push({
        label: "Customer Service Email",
        value: values.customerServiceEmail,
      });
    }
    if (
      values.directCustomerServicePhone !== null &&
      values.directCustomerServicePhone !== undefined &&
      values.directCustomerServicePhone !== ""
    ) {
      header.push({
        label: "Direct Customer Service Phone",
        value: values.directCustomerServicePhone,
      });
    }
    if (
      values.emailInbox !== null &&
      values.emailInbox !== undefined &&
      values.emailInbox !== ""
    ) {
      header.push({
        label: "Email Inbox",
        value: values.emailInbox,
      });
    }
    if (
      values.directCustomerServiceEmail !== null &&
      values.directCustomerServiceEmail !== undefined &&
      values.directCustomerServiceEmail !== ""
    ) {
      header.push({
        label: "Direct Customer Service Email",
        value: values.directCustomerServiceEmail,
      });
    }
    if (
      values.zendeskOutboundPhone !== null &&
      values.zendeskOutboundPhone !== undefined &&
      values.zendeskOutboundPhone !== ""
    ) {
      header.push({
        label: "Zendesk Outbound Phone",
        value: values.zendeskOutboundPhone,
      });
    }

  values.Admin && values.Admin.forEach((view) => {
       let newAdminView = {
         name: "Admin",
         stagingSite: view.adminStagingSite,
         liveSite: view.adminLiveSite,
         isActive: true,
         createdBy,
         _id: view._id,
         program: program._id,
       };
       programView.push(newAdminView);
    });

   values.Consumer && values.Consumer.forEach((view) => {
      let newAdminView = {
        name: "Consumer",
        stagingSite: view.consumerStagingSite,
        liveSite: view.consumerLiveSite,
        isActive: true,
        createdBy,
        _id: view._id,
        program: program._id,
      };
      programView.push(newAdminView);
    });

    setSpinloadingForm(true);
    editProgram({
      variables: {
        input: {
          program: {
            _id: program._id,
            name: program.name,
            header,
            isActive: true,
            createdBy,
            client: clientId,
          },
          programView,
        },
      },
    });
    setVisible(false);
    setSpinloadingForm(false);
    form.resetFields();
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const [deleteProgramView] = useLazyQuery(DELETE_PROGRAM_VIEW);

  function closePopOver() {
    setPopOverShowHide(false);
  }

  const deleteProgramViewById = (id) => {
    deleteProgramView({
      variables: {
        programViewId: id,
      },
    });
  };

  return (
    <>
      {contextHolder}
      <Popover
        content={<div className="text-center bps-midnight">Edit</div>}
        trigger="hover"
      >
        <a
          hidden={!showProgramEdit.includes(currentRole)}
          className="bps-icon-btn m-1"
          onClick={() => {
            getProgramDetails({
              variables: {
                id: programId,
              },
            });
            if (!loading) {
              showModal();
            }
          }}
        >
          <Image src="icons/edit.svg" width="20px" />
        </a>
      </Popover>
      <Modal
        title="Add Program"
        open={visible}
        width={1000}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              editProgramById(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        onCancel={() => {
          handleCancel();
        }}
      >
        <Spin spinning={spinloadingForm}>
          <Form
            form={form}
            name="programForm"
            layout="vertical"
            onFinish={editProgramById}
          >
            <Row>
              <p>
                <b>Program Contact Information</b>
              </p>
            </Row>
            <Row>
              <Col>
                <Form.Item
                  label="Program Name"
                  name="programName"
                  validateFirst={true}
                  rules={[
                    {
                      required: true,
                      message: "Please enter Program Name",
                    },
                    {
                      pattern: patterns.namePattern,
                      message:
                        "Program name can only start with an alphabetic character",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="customerServicePhone"
                  label="Customer Service Phone"
                  validateFirst={true}
                  rules={[
                    {
                      min: 10,
                      message: "Phone number should be of 10 digit long.",
                    },
                    {
                      max: 10,
                      message: "Phone number should be of 10 digit long.",
                    },
                    {
                      pattern: patterns.phoneNumberCheckPattern,
                      message: "Phone numbers can only contain numbers.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="customerServiceEmail"
                  label="Customer Service Email"
                  validateFirst={true}
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="directCustomerServicePhone"
                  label="Direct Customer Service Phone"
                  validateFirst={true}
                  rules={[
                    {
                      min: 10,
                      message: "Phone number should be of 10 digit long.",
                    },
                    {
                      max: 10,
                      message: "Phone number should be of 10 digit long.",
                    },
                    {
                      pattern: patterns.phoneNumberCheckPattern,
                      message: "Phone numbers can only contain numbers.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="zendeskOutboundPhone"
                  label="Zendesk Outbound Phone"
                  validateFirst={true}
                  rules={[
                    {
                      min: 10,
                      message: "Phone number should be of 10 digit long.",
                    },
                    {
                      max: 10,
                      message: "Phone number should be of 10 digit long.",
                    },
                    {
                      pattern: patterns.phoneNumberCheckPattern,
                      message: "Phone numbers can only contain numbers.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="emailInbox"
                  label="Email Inbox"
                  validateFirst={true}
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="directCustomerServiceEmail"
                  label="Direct Customer Service Email"
                  validateFirst={true}
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <hr />
              <Row>
                <p>
                  <b>Program Website Links</b>
                </p>
              </Row>
              <Row className="my-2 py-2 bps-bg-light">
                <Form.List name="Consumer">
                  {(fields, { add, remove }) => (
                    <>
                      <Row className=" py-2 bps-bg-light">
                        <Col sm={10}>
                          <h6>Consumer Site</h6>
                        </Col>
                        <Col sm={2}>
                          <Form.Item>
                            <Button
                              className="btn-sm my-1 bps-secondary-button-sm float-end align-items-center"
                              onClick={() => add()}
                            >
                              + Sites
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                      {fields.map(({ key, name, ...restField }) => (
                        <Row
                          className="my-2 py-2 bps-bg-light"
                          key={`popover-${key}`}
                        >
                          <Col>
                            <h6>STAGING SITE -CONSUMER</h6>
                            <Form.Item
                              {...restField}
                              name={[name, "consumerStagingSite"]}
                              validateFirst={true}
                              rules={[
                                // {
                                //   required: true,
                                //   message: "Please enter Consumer Staging Site",
                                // },
                                { validator: urlValidator },
                              ]}
                            >
                              <Input
                                placeholder={
                                  "https://staging-sunpower.bp.solutions"
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <h6>LIVE SITE -CONSUMER</h6>
                            <Form.Item
                              {...restField}
                              name={[name, "consumerLiveSite"]}
                              validateFirst={true}
                              rules={[
                                // {
                                //   required: true,
                                //   message: "Please enter Consumer Live Site",
                                // },
                                { validator: urlValidator },
                              ]}
                            >
                              <Input
                                placeholder={
                                  "https://staging-sunpower.bp.solutions"
                                }
                              />
                            </Form.Item>
                          </Col>
                          {fields.length > 1 ? (
                            <Popover
                              content={
                                <>
                                  <div className="text-center bps-midnight">
                                    <p>
                                      You will lose access to all the notes
                                      related to this program view
                                    </p>
                                    <p>Want to remove this program view?</p>
                                    <Button
                                      className="d-inline-flex p-2 bps-secondary-button-sm m-1"
                                      onClick={() => {
                                        const consumer =
                                          form.getFieldValue("Consumer");
                                        const id = consumer[name]._id;
                                        deleteProgramViewById(id);
                                        remove(name);
                                      }}
                                    >
                                      Remove
                                    </Button>
                                    <Button
                                      className="d-inline-flex p-2 bps-secondary-button-sm m-1"
                                      onClick={closePopOver}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </>
                              }
                              trigger="click"
                              open={popoverKey === `popover-${key}`}
                              onOpenChange={(visible) => {
                                if (visible) {
                                  setPopoverKey(`popover-${key}`);
                                } else {
                                  setPopoverKey(null);
                                }
                              }}
                            >
                              <Tooltip
                                title="Delete program view"
                                placement="bottom"
                              >
                                <MinusCircleOutlined className="dynamic-delete-button" />
                              </Tooltip>
                            </Popover>
                          ) : null}
                        </Row>
                      ))}
                    </>
                  )}
                </Form.List>
              </Row>
              <Row className="my-2 py-2 bps-bg-light">
                <Form.List name="Admin">
                  {(fields, { add, remove }) => (
                    <>
                      <Row className=" py-2 bps-bg-light">
                        <Col sm={10}>
                          <h6>Administrator Site</h6>
                        </Col>
                        <Col sm={2}>
                          <Form.Item>
                            <Button
                              className="btn-sm my-1 bps-secondary-button-sm float-end align-items-center"
                              onClick={() => add()}
                            >
                              + Sites
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                      {fields.map(({ key, name, ...restField }) => (
                        <Row className="my-2 py-2 bps-bg-light" key={key}>
                          <Col>
                            <h6>STAGING SITE -ADMIN</h6>
                            <Form.Item
                              {...restField}
                              name={[name, "adminStagingSite"]}
                              validateFirst={true}
                              rules={[
                                // {
                                //   required: true,
                                //   message: "Please enter Admin Staging Site",
                                // },
                                { validator: urlValidator },
                              ]}
                            >
                              <Input
                                placeholder={
                                  "https://staging-sunpower.bp.solutions"
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <h6>LIVE SITE -ADMIN</h6>
                            <Form.Item
                              {...restField}
                              name={[name, "adminLiveSite"]}
                              validateFirst={true}
                              rules={[
                                // {
                                //   required: true,
                                //   message: "Please enter Admin Live Site",
                                // },
                                { validator: urlValidator },
                              ]}
                            >
                              <Input
                                placeholder={
                                  "https://staging-sunpower.bp.solutions"
                                }
                              />
                            </Form.Item>
                          </Col>
                          {fields.length > 1 ? (
                            <Popover
                              content={
                                <>
                                  <div className="text-center bps-midnight">
                                    <p>
                                      You will lose access to all the notes
                                      related to this program view
                                    </p>
                                    <p>Want to remove this program view?</p>
                                    <Button
                                      className="d-inline-flex p-2 bps-secondary-button-sm m-1"
                                      onClick={() => {
                                        const admin =
                                          form.getFieldValue("Admin");
                                        const id = admin[name]._id;
                                        deleteProgramViewById(id);
                                        remove(name);
                                      }}
                                    >
                                      Remove
                                    </Button>
                                    <Button
                                      className="d-inline-flex p-2 bps-secondary-button-sm m-1"
                                      onClick={closePopOver}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </>
                              }
                              trigger="click"
                              open={popoverKey === key}
                              onOpenChange={(visible) => {
                                if (visible) {
                                  setPopoverKey(key);
                                } else {
                                  setPopoverKey(null);
                                }
                              }}
                            >
                              <Tooltip
                                title="Delete program view"
                                placement="bottom"
                              >
                                <MinusCircleOutlined className="dynamic-delete-button" />
                              </Tooltip>
                            </Popover>
                          ) : null}
                        </Row>
                      ))}
                    </>
                  )}
                </Form.List>
              </Row>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default EditProgramForm;
