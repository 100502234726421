import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Constants from "../Constants";
import AuthContext from "../context/AuthProvider";
import secureLocalStorage from "react-secure-storage";



const useAuth = (val) => {
    localStorage.setItem(Constants.loggedIn, val.isloggedIn)
    let logIn = localStorage.getItem(Constants.loggedIn) === 'true' ? true : false
    const user = { isloggedIn: logIn };
    return user;
};

export const AuthorizedUsers = (props) => {
    let userRole = secureLocalStorage.getItem(Constants.userRole);
    return props.role.find((rol) => true ? userRole === rol : false) ? <Outlet /> : <Navigate to={'/intranet'} replace />;
}

const ProtectedRoutes = () => {
    const location = useLocation();
    const { auth } = useContext(AuthContext)
    const isAuth = useAuth(auth);
    return isAuth.isloggedIn ? <Outlet /> : <Navigate to={'/intranet-signin'} state={{ from: location }} replace />;
}

export default ProtectedRoutes;



