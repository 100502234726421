import React, { Component, Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import IntranetNavigation from './IntranetNavigation';
import { Container, Row, Col, Image, ListGroup, Tab, Card, Nav } from 'react-bootstrap';
import './Intranet.css';
import '../stylingfiles/BPS.css';
import SolutionsFest2022 from './SolutionsFest2022/SolutionsFest2022';
import SolutionsFest2021 from './SolutionsFest2022/SolutionsFest2021';



const IntranetSolutionsFest = () => {
  return (
    <Container fluid className="px-0">
      <IntranetNavigation />
      <section className="pt-5 bps-bg-light">
        <Container className="pt-5">
          <Row className="px-5 align-items-center justify-content-between">
            <Col md={4}>
              <Image fluid src='../images/BPS-SolutionsFest-2022-logo.png' />
            </Col>
            <Col md={6}>
              <Image fluid src='../images/SolutionsFest-Header.png' />
            </Col>
          </Row>
        </Container>
      </section>
      <div className="bps-bg-wave-bottom" style={{transform: 'scaleY(-1)'}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="179" viewBox="0 0 1920 179"><g transform="translate(2774 7314)"><path d="M0-30S118.465,0,466.825,0s563.322-60,926.616-60S1920,0,1920,0V97H0Z" transform="translate(-2774 -7254)" fill="#faf5f0" opacity="0.5" /><path d="M0-33S118.465,10,466.825,10s563.322-50,926.616-50S1920,0,1920,0V97H0Z" transform="translate(-2774 -7232)" fill="#faf5f0" /></g></svg>
      </div>
      <Container fluid>
        <Row className="pt-lg-5">
          <Col>
            <Tab.Container defaultActiveKey='2022'>
              <Row>
                <Col sm={2} className='shadow-sm mb-4'>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="2022">2022</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="2021">2021</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={10}>
                  <Tab.Content>

                    <Tab.Pane eventKey="2022">
                      <h5 className='fw-bold bps-midnight border-bottom pb-2'>2022</h5>
                      <SolutionsFest2022 />
                    </Tab.Pane>

                    <Tab.Pane eventKey="2021">
                      <h5 className='fw-bold bps-midnight border-bottom pb-2'>2021</h5>
                      <SolutionsFest2021 />
                    </Tab.Pane>

                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default IntranetSolutionsFest