import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Form, Input, message, Spin, spin } from 'antd';
import { useMutation, gql } from '@apollo/client';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Intranet.css';
import '../stylingfiles/BPS.css';
import AuthContext from '../context/AuthProvider'
import Constants from '../Constants';
import secureLocalStorage from "react-secure-storage";
import { redirect, useNavigate, Link } from 'react-router-dom';

const IntranetSignIn = () => {
    localStorage.clear();
    secureLocalStorage.clear();
    const [form] = Form.useForm();
    const { setAuth } = useContext(AuthContext)
    secureLocalStorage.setItem(Constants.loggedIn, false);
    const redirect = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [emailState, setEmail] = useState();
    const LOGIN = gql`
    mutation Login($email:String!, $password:String!) {
        login(input: { email: $email, password: $password }) {
        username
        name
        email
        encryptedEmail
        phone
        isActive
        id
        createdAt
        userRole
        registeredDate
        createdBy
        }
    }`

    const [loginAndGetData] = useMutation(LOGIN)

    const handleFormSubmit = async (values) => {
        const { email, password } = values

        try {
            setLoading(true);
            const res = await loginAndGetData({
                variables: {
                    email, password
                }
            })

            if (res.data) {
                setLoading(false);
                let currentRole = res.data.login.userRole
                secureLocalStorage.setItem(Constants.userRole, currentRole)

                let id = res.data.login.id;
                secureLocalStorage.setItem(Constants.userId, id);

                let token = res.data.login.encryptedEmail
                secureLocalStorage.setItem(Constants.token, token);

                let name = res.data.login.name
                secureLocalStorage.setItem(Constants.username, name);

                let isActive = res.data.login.isActive
                secureLocalStorage.setItem(Constants.ifActive, isActive);

                let email = res.data.login.email
                secureLocalStorage.setItem(Constants.email, email)

                setAuth({ isloggedIn: true })
                redirect('/');
            }

        } catch (err) {
            setLoading(false);
            messageApi.open({
                type: 'error',
                content: err.message,
                duration: 10,
            });
        }
    }

    return (
        <>
            {contextHolder}
            <Spin spinning={loading}>

                <Container fluid className="px-0">
                    <section className="card-order-platform-bar shadow-sm">
                        <Container fluid>
                            <Row className="justify-content-between align-items-center">
                                <Col>
                                    <a href="/">
                                        <img src="images/Logo.svg" className="logo" />
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section className="bps-bg-light text-center">
                        <Container fluid className="py-5">
                            <Row className="justify-content-evenly">
                                <Col lg={5}>
                                    <h5 className="bps-midnight-tint">
                                        Best Payment Solutions Intranet
                                    </h5>
                                    <h1 className="fs-48 bps-midnight">Sign In</h1>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section>
                        <Container className="py-5">
                            <Row className="justify-content-center p-4">
                                <Col lg={4} className="bps-card py-5">
                                    <Form
                                        form={form}
                                        layout="vertical"
                                        name="dynamic_rule"
                                        onFinish={(values) => handleFormSubmit(values)}
                                    >
                                        <Row className="justify-content-center">
                                            <Col lg={10}>
                                                <Form.Item
                                                    className="mb-3 form-label-style"
                                                    name="email"
                                                    label={"Email"}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Email is required",
                                                        },
                                                        {
                                                            type: "email",
                                                            message: "please enter valid email address",
                                                        },
                                                    ]}
                                                >
                                                    <Input onChange={(event) => setEmail(event.target.value)} />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={10}>
                                                <Form.Item
                                                    className="mb-1  form-label-style"
                                                    name={"password"}
                                                    label={"Password"}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Password is required",
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password placeholder="input password" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="justify-content-center">
                                            <Col lg={10}>
                                                <p className="text-end mt-0 fs-6">

                                                    <Link to="/intranet-forgotpassword"
                                                        state={{

                                                            emailState,
                                                        }}
                                                        className="bps-link-button">
                                                        Forgot Password
                                                    </Link>

                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className="justify-content-center text-center pt-4">
                                            <Button
                                                type="Submit"
                                                className="bps-primary-button mt-4 w-50"
                                            >
                                                Log In
                                            </Button>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </section>
        </Container>
            </Spin>
        </>
    );
}

export default IntranetSignIn;