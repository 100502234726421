import React, { Fragment } from 'react';
import { Menu } from 'antd';
import 'antd/dist/reset.css';
import { Nav, Navbar, Container, NavDropdown, Button, Row, Col } from 'react-bootstrap';
import { withRouter } from "react-router";
import ReactGA from 'react-ga';
import IntranetNavigationFavorites from './IntranetNavigationFavorites';
import '../stylingfiles/Navigation.css';
import './Intranet.css';
import '../stylingfiles/BPS.css';
import { useLocation } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Constants, { roles } from '../Constants';

const { SubMenu } = Menu;

ReactGA.initialize('UA-166691488-2');

ReactGA.pageview(window.location.pathname);

const NavigationLinks = (props) => {

  const location = useLocation();
  const currentRole = secureLocalStorage.getItem(Constants.userRole);

  const users = [roles.ROOT];
  const home = [roles.ROOT, roles.ADMINISTRATOR, roles.STAFF];
  const appsAndSoftwares = [roles.ROOT, roles.ADMINISTRATOR, roles.STAFF];
  const clientProgram = [roles.ROOT, roles.ADMINISTRATOR, roles.STAFF];
  const employeeResource = [roles.ROOT, roles.ADMINISTRATOR, roles.STAFF];
  const solutionsFest = [roles.ROOT, roles.ADMINISTRATOR, roles.STAFF];

  return (
    <section className='border-bottom'>
    <Navbar bg="white" expand="lg" className="shadow-sm px-md-2" fixed="top">
      <Container fluid>
            <Navbar.Brand href="/intranet">
              <img src="images/Primary Logo - Horizontal - Color.png" className="logo d-none d-lg-block" alt='Best Payment Solutions' />
              <img src="images/Logo-Mobile.svg" className="logo-mobile d-block d-lg-none" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="primary-nav" />
            <Navbar.Collapse id="primary-nav">
              <Nav className="ms-auto" activeKey={location.pathname}>
                <Nav.Link href="/intranet" className="primary-nav-links mx-lg-2">Home</Nav.Link>
                <Nav.Link href="/intranet-appssoftware" className="primary-nav-links mx-lg-2">Apps & Software</Nav.Link>
                <Nav.Link href="/intranet-clientprograms" className="primary-nav-links mx-lg-2">Client Programs</Nav.Link>
                <Nav.Link href="/intranet-employeeresources" className="primary-nav-links mx-lg-2">Employee Resources</Nav.Link>
                <Nav.Link href="/intranet-solutionsfest" className="primary-nav-links mx-lg-2">SolutionsFest</Nav.Link>
              <Nav.Link href="/intranet-users" className="primary-nav-links mx-lg-2" hidden={!users.includes(currentRole)}>Users</Nav.Link>
                <span className="nav-divider mx-2 d-none d-lg-block" />
                <Nav.Link href="/intranet-signin" className="primary-nav-links mx-lg-2">Sign Out</Nav.Link>
              </Nav>
            </Navbar.Collapse>
      </Container>
      </Navbar >
      <IntranetNavigationFavorites />
        </section>

  );
}
// const IntranetNavigation = withRouter(NavigationLinks);
export default NavigationLinks;

